import {useEffect, useState} from 'react'

import dynamic from 'next/dynamic'
import Script from 'next/script'

export default function ReactAppBlock({value}) {
  const [scriptsLoaded, setScriptsLoaded] = useState(false)
  let src
  let loadGoogleMaps = false
  switch (value.appId) {
    case 1:
      src = '/cc3-react-apps/optometristSalaryCalculator.min.js'
      break
    case 2:
      src = '/cc3-react-apps/optometryDebtCalculator.min.js'
      break
    case 3:
      src = '/cc3-react-apps/whichOptometrySchool.min.js'
      break
    case 4:
      loadGoogleMaps = true
      src = '/cc3-react-apps/timeToHireCalculator.min.js'
      break
    case 5:
      src = '/cc3-react-apps/costOfHiringCalculator.min.js'
      break
    case 6:
      src = '/cc3-react-apps/ophthalmologyResidencyTool.min.js'
      break
  }
  useEffect(() => {
    // If the react app uses google maps, load it before mounting the app
    if (loadGoogleMaps) {
      const script = document.createElement('script')
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBVMya34yTmhCU1-2tGmkvV5Sf7sO320CY&libraries=places&v=3'
      document.body.appendChild(script)
      script.addEventListener('load', () => {
        setScriptsLoaded(true)
      })
    } else {
      setScriptsLoaded(true)
    }
  }, [loadGoogleMaps])

  const ReactTooltip = dynamic(() => import('react-tooltip'), {
    ssr: false,
  })
  const loadTooltips = () => {
    const tooltips = document.querySelectorAll('.fal.fa-question-circle')
    tooltips.forEach((tooltip) => {
      const content = tooltip.getAttribute('title')
      const tooltipHtml = `<span 
            data-tip='${content}' 
            style='vertical-align: text-bottom;'
          >
            <span style='
              padding-left: 4px;
              padding-right: 4px;
              text-align: center; 
              border: 2px solid gray;
              font-size: 10px; 
              color: gray;
              border-radius: 50%;'
            >
              ?
            </span>
          </span>`
      tooltip.insertAdjacentHTML('afterend', tooltipHtml)
    })
  }
  return (
    <>
      <ReactTooltip />
      <div id="root" className="mb-3">
        {scriptsLoaded && (
          <Script src={src} strategy="afterInteractive" onLoad={loadTooltips} />
        )}
      </div>
    </>
  )
}
