import Link from 'next/link'

export default function ResourcesDropdown() {
  return (
    <span className="nav-item dropdown me-3 text-uppercase">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="resourcesDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Resources
      </a>
      <div className="dropdown-menu" aria-labelledby="resourcesDropdown">
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          className="dropdown-item"
          href="/resources/?media_type=Reports&utm_campaign=desktop_sticky_dropdown"
        >
          Reports
        </a>
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          className="dropdown-item"
          href="/resources/?media_type=Cheat%20Sheets&utm_campaign=desktop_sticky_dropdown"
        >
          Cheat Sheets
        </a>
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          className="dropdown-item"
          href="/resources/?q=Ultimate%20Guide&utm_campaign=desktop_sticky_dropdown"
        >
          Ultimate Guides
        </a>
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          className="dropdown-item"
          href="/resources/?media_type=Calculator&utm_campaign=desktop_sticky_dropdown"
        >
          Calculators &amp; Tools
        </a>
        <Link href="/interventional-mindset" className="dropdown-item">
          Interventional Mindset
        </Link>
        <div className="dropdown-divider" />
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          className="dropdown-item"
          href="/resources/?utm_campaign=desktop_sticky_dropdown"
        >
          See All Resources
        </a>
      </div>
    </span>
  )
}
