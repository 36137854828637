import {useContext} from 'react'

import Script from 'next/script'

import {ResourceComponentContext} from '../../components/resources/ResourceComponent'
import {ADROLL_ADV_ID, ADROLL_PIX_ID, ANALYTICS_ENABLED} from './config'

export function trackAdRollEvent(segment: string) {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window.__adroll.record_user({adroll_segments: segment})
  } catch (e) {
    // continue regardless of error
  }
}

export function AdRollInit() {
  return (
    <Script
      id="adroll-init"
      type="text/plain"
      data-cookieconsent="marketing"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          adroll_adv_id = "${ADROLL_ADV_ID}";
          adroll_pix_id = "${ADROLL_PIX_ID}";
          adroll_version = "2.0";

          (function(w, d, e, o, a) {
              w.__adroll_loaded = true;
              w.adroll = w.adroll || [];
              w.adroll.f = [ 'setProperties', 'identify', 'track' ];
              var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
                      + "/roundtrip.js";
              for (a = 0; a < w.adroll.f.length; a++) {
                  w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                      return function() {
                          w.adroll.push([ n, arguments ])
                      }
                  })(w.adroll.f[a])
              }

              e = d.createElement('script');
              o = d.getElementsByTagName('script')[0];
              e.async = 1;
              e.src = roundtripUrl;
              o.parentNode.insertBefore(e, o);
          })(window, document);
          adroll.track("pageView");
        `,
      }}
    />
  )
}

export function AdRollResourcePagePixels() {
  const {resource} = useContext(ResourceComponentContext)

  if (!resource) return null

  const ocularDiseaseTags = [
    'diabetes',
    'diabetic retinopathy',
    'disease management',
    'endophthalmitis',
    'fundus',
    'geographic atrophy',
    'high risk medications',
    'IOLs',
    'lasers',
    'macula',
    'macular',
    'macular degeneration',
    'maculopathy',
    'medical optometry',
    'medication guide',
    'medications',
    'metamorphopsia',
    'MIGS',
    'multiple sclerosis',
    'oct',
    'ocular disease',
    'ocular ischemic syndrome',
    'pupil abnormalities',
    'surgery',
    'surgical videos',
    'telehealth',
    'ultra-widefield imaging',
    'uveitis',
    'glaucoma',
    'concussion',
    'traumatic brain injury',
    'traumatic optic neuropathy',
    'cranial nerves',
    'dementia',
    'tbi',
    'neuro rehab',
    'neuro-optometric rehabilitation',
    'neurological disorder',
    'nerve palsy',
    'optic nerve pallor',
    'central serous choreoretinopathy',
    'retina',
    'retinal detachment',
  ]

  const careerNetworkTags = [
    'salary and compensation',
    'wage gap',
    'contract negotiation',
    'contracts',
    'contractor',
    'independent contractors',
    'negotiation',
    'roth ira',
    'gender',
    'gender equalityjob search',
    'employment',
    'employment contract',
    'non-clinical careers',
    'work-life balance',
    'academia',
    'career profiles',
    'company culture',
    'corporate lease',
    'corporate optometry',
    'cover letter',
    'curriculum vitae',
    'day in the life',
    'values assessment',
    'vamc',
    'veterans',
    'goal setting',
    'interview',
    'lenscrafters sublease',
    'medical model',
    'modalities',
    'networking',
    'setting',
    'rural areas',
    'resources',
    'resume',
    'relocation',
    'research',
    'references',
    'specialty',
    'public health',
    'fellowship',
    'IHS',
  ]

  const practiceMgmtTags = [
    'business management',
    'business metrics',
    'business plan',
    'brand marketing',
    'billing and coding',
    'building a practice',
    'buy in',
    'buy out',
    'doctor driven dispensing',
    'ehr',
    'electronic health record',
    'employees',
    'employer',
    'equipment',
    'eyecare business',
    'eyecare employer',
    'hr',
    'human resources',
    'job descriptions',
    'key performance indicators',
    'leadership',
    'management',
    'management advice',
    'manager',
    'managerial skills',
    'managing optometrists',
    'marketing',
    'marketing hacks',
    'open cold',
    'opening a practice cold',
    'opening a private practice',
    'optical staff',
    'optometry office design',
    'ownership',
    'patient loyalty',
    'practice growth',
    'practice owner',
    'practice ownership',
    'private equity',
    'private practice',
    'recruiting',
    'revenue',
    'small business',
    'social media marketing',
    'staff',
    'staff morale',
    'staff retention',
    'staffing',
    'starting an eye clinic',
    'hiring',
    'growth',
    'inside the company',
    'optometry practice',
    'profit',
    'onboarding',
    'facebook',
    'facebook ads',
    'morale',
    'referral sources',
    'sublease',
    'software',
    'selling',
    'practice',
    'press release',
    'product reviews',
    'products',
    'pto',
    'retirement',
    'sales',
    'second pair sales',
    'patient satisfaction',
    'optometry business',
    'optometry clinics',
    'optometry news',
    'optical',
    'optician',
    'opticians',
    'paperwork',
    'emr',
    'ophthalmic tech',
    'ophthalmic technician',
    'logistics',
    'emotional intelligence',
  ]

  return (
    <>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt=""
        className="position-absolute"
        data-cookieconsent="marketing"
        data-cookieblock-src="https://d.adroll.com/ipixel/XLHR35LJ4RDLRHIUHVDXA7/Y2GGJTUFBZCXZOMEBVDC4Z?name=9905dfad"
        width="1"
        height="1"
      />
      {resource.roles?.includes('Ophthalmologist') && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          alt=""
          className="position-absolute"
          data-cookieconsent="marketing"
          data-cookieblock-src="https://d.adroll.com/ipixel/XLHR35LJ4RDLRHIUHVDXA7/Y2GGJTUFBZCXZOMEBVDC4Z?name=91b190bc"
          width="1"
          height="1"
        />
      )}
      {resource.tags?.some((tag) => ocularDiseaseTags.indexOf(tag) >= 0) && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className="position-absolute"
          alt=""
          data-cookieconsent="marketing"
          data-cookieblock-src="https://d.adroll.com/ipixel/XLHR35LJ4RDLRHIUHVDXA7/Y2GGJTUFBZCXZOMEBVDC4Z?name=d7933b46"
          width="1"
          height="1"
        />
      )}
      {resource.tags?.some((tag) => careerNetworkTags.indexOf(tag) >= 0) && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className="position-absolute"
          alt=""
          data-cookieconsent="marketing"
          data-cookieblock-src="https://d.adroll.com/ipixel/XLHR35LJ4RDLRHIUHVDXA7/Y2GGJTUFBZCXZOMEBVDC4Z?name=618c5b1f"
          width="1"
          height="1"
        />
      )}
      {resource.tags?.some((tag) => practiceMgmtTags.indexOf(tag) >= 0) && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className="position-absolute"
          alt=""
          data-cookieconsent="marketing"
          data-cookieblock-src="https://d.adroll.com/ipixel/XLHR35LJ4RDLRHIUHVDXA7/Y2GGJTUFBZCXZOMEBVDC4Z?name=56c53c77"
          width="1"
          height="1"
        />
      )}
      {resource.clinicalSpecialty === 'Contact Lens' && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          alt=""
          className="position-absolute"
          data-cookieconsent="marketing"
          data-cookieblock-src="https://d.adroll.com/ipixel/XLHR35LJ4RDLRHIUHVDXA7/Y2GGJTUFBZCXZOMEBVDC4Z?name=266d34c9"
          width="1"
          height="1"
        />
      )}
    </>
  )
}
