import {PortableText} from '@portabletext/react'

import {StickyFooterType} from '../../core/schemas'
import {featuredTextSerializers} from '../../core/serializers'

export default function StickyFooterContent({
  stickyFooter,
}: {
  stickyFooter: StickyFooterType
}) {
  return (
    <div className="mb-4" id="stickyFooterContent">
      <div className="card" style={{border: '3px solid #007bff'}}>
        <div className="card-body">
          <div className="mt-3 break-word">
            <PortableText
              value={stickyFooter.stickyFooterContent}
              components={{
                marks: {
                  ...featuredTextSerializers.marks,
                  textColor: (props) => (
                    <span style={{color: props.value.hex}}>
                      {props.children}
                    </span>
                  ),
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
