const PDFEmbed = ({url}: {url: string}) => {
  return (
    <div className="pdf-embed">
      <iframe
        src={url}
        frameBorder="0"
        width="100%"
        height="600px"
        allowFullScreen
        title="PDF Embed"
      />
      <a href={url} className="text-primary text-decoration-underline">
        Download
      </a>
    </div>
  )
}

export default PDFEmbed
