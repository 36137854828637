import Image from 'next/legacy/image'

import {z} from 'zod'

import {linkify} from '../../core/utils'
import styles from '../../styles/modules/ImageComponent.module.scss'
import FancyboxWrapper from './FancyboxWrapper'

const ImageComponentProps = z.object({
  src: z.string(),
  width: z.number().optional(),
  height: z.number().optional(),
  alt: z.string().optional(),
  link: z.string().optional(),
  priority: z.boolean().optional(),
  fancyboxId: z.string().optional(),
})

const ImageComponent = ({
  src,
  width,
  height,
  link,
  alt,
  priority = false,
  fancyboxId,
}: z.infer<typeof ImageComponentProps>) => {
  if (link) {
    return (
      <LinkableImage
        src={src}
        width={width}
        height={height}
        link={link}
        alt={alt}
        priority={priority}
      />
    )
  }
  return (
    <FancyboxImage
      src={src}
      width={width}
      height={height}
      alt={alt}
      priority={priority}
      id={fancyboxId || 'gallery'}
    />
  )
}
export default ImageComponent

const LinkableImage = ({
  src,
  width,
  height,
  link,
  alt,
  priority = false,
}: z.infer<typeof ImageComponentProps>) => {
  if (!link) return <></>

  return (
    <div
      role="button"
      tabIndex={0}
      className={`${styles.nextImageHack} mb-3 cursor-pointer`}
    >
      {linkify(
        <Image
          src={src}
          width={width}
          height={height}
          alt={alt}
          priority={priority}
        />,
        link,
        {
          newTab: true,
        },
      )}
    </div>
  )
}

const FancyboxImageProps = ImageComponentProps.extend({
  caption: z.string().optional(),
  id: z.string(),
  objectFit: z
    .enum(['fill', 'contain', 'cover', 'none', 'scale-down'])
    .optional(),
  videoSrc: z.string().optional(),
})

/**
 * Used for creating fancybox images, galleries, or videos. For videos, src is used for the image displayed
 * while videoSrc is used for the video displayed once clicked.
 */
export const FancyboxImage = ({
  src,
  videoSrc,
  width,
  height,
  alt,
  priority = false,
  caption = '',
  id,
  objectFit = 'cover',
}: z.infer<typeof FancyboxImageProps>) => {
  return (
    <FancyboxWrapper id={id} src={videoSrc || src} caption={caption}>
      <Image
        src={src}
        width={width}
        height={height}
        alt={alt}
        objectFit={objectFit}
        priority={priority}
      />
    </FancyboxWrapper>
  )
}
