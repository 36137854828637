import {useContext} from 'react'

import {baseUrl} from '../../core/constants'
import {getImageSource} from '../../core/sanity'
import {ResourcePageContext} from '../../pages/resources/[slug]'

interface ParselyAuthorMetadataProps {
  title: string
  imgSrc: string
  slug: string
}

interface ParselyGenericMetadataProps {
  url: string
  title: string
  section?: string
}

export function ParselyResourceMetadata() {
  const {activeResource: resource} = useContext(ResourcePageContext)
  const imgSrc = getImageSource(resource.featuredImage).url()
  // Add some additional tags to enable Parsely filtering/reporting
  const tags = resource.tags ? [...resource.tags] : []
  tags.push(`eoe/clinical-specialty/${resource.clinicalSpecialty}`)
  tags.push(`eoe/category/${resource.category}`)
  tags.push(`eoe/media-type/${resource.mediaType}`)
  resource.roles?.map((role) => tags.push(`eoe/role/${role}`))
  if (resource.containsGatedContent) {
    tags.push('eoe/gated-content')
  }
  if (resource.includeCampaignTracker) {
    tags.push('eoe/campaign-tracker')
  }
  if (resource.sponsor) {
    tags.push(`eoe/sponsor/${resource.sponsor.name}`)
  }
  return (
    <>
      {resource.authors.map((author, i) => (
        <meta key={i} name="parsely-author" content={author.name} />
      ))}
      <meta name="parsely-image-url" content={imgSrc} />
      <meta
        name="parsely-link"
        content={`${baseUrl}/resources/${resource.slug}/`}
      />
      <meta name="parsely-pub-date" content={resource.publishedAt} />
      <meta name="parsely-section" content="Resources" />
      <meta name="parsely-tags" content={tags.join(',')} />
      <meta name="parsely-title" content={resource.title} />
      <meta name="parsely-type" content="post" />
    </>
  )
}

export function ParselyAuthorMetadata({
  title,
  imgSrc,
  slug,
}: ParselyAuthorMetadataProps) {
  return (
    <>
      <meta name="parsely-image-url" content={imgSrc} />
      <meta name="parsely-link" content={`${baseUrl}/authors/${slug}/`} />
      <meta name="parsely-section" content="Resources" />
      <meta name="parsely-title" content={title} />
      <meta name="parsely-type" content="index" />
    </>
  )
}

export function ParselyMetadata({
  url,
  title,
  section,
}: ParselyGenericMetadataProps) {
  return (
    <>
      <meta name="parsely-link" content={url} />
      <meta name="parsely-title" content={title} />
      <meta name="parsely-type" content="index" />
      {section && <meta name="parsely-section" content={section} />}
    </>
  )
}
