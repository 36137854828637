import type {FieldErrors, FieldName, FieldValues} from 'react-hook-form'

import {ErrorMessage, FieldValuesFromFieldErrors} from '@hookform/error-message'

export default function FormError<TFieldValues extends FieldValues>({
  name,
  errors,
}: {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>>
  errors: FieldErrors<TFieldValues>
}) {
  return (
    <div className="text-danger mb-1">
      <ErrorMessage errors={errors} name={name} />
    </div>
  )
}
