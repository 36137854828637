import {useContext, useEffect} from 'react'

import $ from 'jquery'

import {useGPT} from '../../../core/ads/contexts/GPTContext'
import {insertGPTInReadAd, shouldUseCustomAds} from '../../../core/ads/utils'
import {useUser} from '../../../core/auth'
import {ResourceComponentContext} from '../ResourceComponent'

export default function ResourceInReadAds() {
  const {GPTHasLoaded} = useGPT()
  const {resource} = useContext(ResourceComponentContext)
  const {user, isLoading: isUserLoading} = useUser()

  useEffect(() => {
    const slots: Array<googletag.Slot> = []
    const refreshAdCleanups: Array<() => void> = []
    if (
      resource.showAds &&
      resource.showInReadAds &&
      GPTHasLoaded &&
      !isUserLoading
    ) {
      try {
        const ps = $(`#resource-body__${resource._id} h2`)
          .not('.alert h2')
          .not('#author-bio h2')
          .not('.unlock-gated-content h2')
          .not('#gated-content h2')
          .not('.lottie-content-block .h2')

        if (ps.length >= resource.inReadAdIteration) {
          if (ps.length > 0) {
            googletag.cmd.push(() => {
              googletag.pubads().enableLazyLoad({
                fetchMarginPercent: 100,
                renderMarginPercent: 50,
                mobileScaling: 3.0,
              })
              googletag.enableServices()
            })
            ps.each((index: number) => {
              if ((index + 1) % resource.inReadAdIteration === 0) {
                // inserts ad before specific headings -> ps[index]
                if (
                  shouldUseCustomAds(resource) &&
                  resource.customAd?.inReadCode
                ) {
                  insertGPTInReadAd(
                    ps[index],
                    user,
                    resource,
                    slots,
                    refreshAdCleanups,
                    resource.customAd?.inReadCode,
                  )
                } else {
                  insertGPTInReadAd(
                    ps[index],
                    user,
                    resource,
                    slots,
                    refreshAdCleanups,
                  )
                }
              }
            })
          }
        }
      } catch (e) {
        // continue regardless of error
      }
    }

    return function cleanup() {
      if (googletag.destroySlots) {
        googletag.destroySlots(slots)
      }
      if (refreshAdCleanups.length) {
        refreshAdCleanups.forEach((cleanup) => {
          cleanup()
        })
      }
    }
  }, [GPTHasLoaded, isUserLoading, resource, user])

  return <></>
}
