import {useEffect, useState} from 'react'
import ReactPlayer from 'react-player'

type VideoEmbedBlockProps = {
  height?: string
  url: string
  id?: string
}
function VideoEmbedBlock(props: VideoEmbedBlockProps) {
  const [hasWindow, setHasWindow] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <div className="mb-3 h-100" id={props.id}>
      {hasWindow && (
        <ReactPlayer
          url={props.url}
          controls
          width="100%"
          height={props.height ?? undefined}
        />
      )}
    </div>
  )
}
export default VideoEmbedBlock
