import Script from 'next/script'

import {ANALYTICS_ENABLED, HUBSPOT_ACCOUNT_ID} from './config'

// https://developers.hubspot.com/docs/api/events/tracking-code#track-page-view
export const trackHubspotPageView = (url: string) => {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window._hsq.push(['setPath', url])
    window._hsq.push(['trackPageView'])
  } catch (e) {
    // continue regardless of error
  }
}

export function HubspotInit() {
  return (
    <Script
      src={`//js.hs-scripts.com/${HUBSPOT_ACCOUNT_ID}.js`}
      id="hs-script-loader"
      strategy="afterInteractive"
      type="text/plain"
      data-cookieconsent="marketing"
    />
  )
}
