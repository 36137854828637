import {useContext} from 'react'

import {baseUrl} from '../../../core/constants'
import {getImageSource, portableTextToString} from '../../../core/sanity'
import {ResourcePageContext} from '../../../pages/resources/[slug]'
import Metadata from '../../head/Metadata'

export default function ResourceMetadata() {
  const {activeResource: resource} = useContext(ResourcePageContext)
  return (
    <Metadata
      title={resource.title}
      url={`${baseUrl}/resources/${resource.slug}/`}
      type="article"
      imgSrc={
        resource.featuredImage
          ? getImageSource(resource.featuredImage).url()
          : null
      }
      description={
        resource.featuredText
          ? portableTextToString(resource.featuredText)
          : resource.title
      }
    />
  )
}
