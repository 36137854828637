import Link from 'next/link'

import {PortableText} from '@portabletext/react'

import BannerAd from '../components/ads/BannerAd'
import ContentBlock from '../components/cards/ContentBlock'
import Carousel from '../components/images/Carousel'
import ImageComponent from '../components/images/ImageComponent'
import ContentGridBlock from '../components/resources/blocks/ContentGridBlock'
import Flashcards from '../components/resources/blocks/Flashcards'
import LeadFormBlock from '../components/resources/blocks/LeadFormBlock'
import LottieFile from '../components/resources/blocks/LottieFile'
import PDFEmbed from '../components/resources/blocks/PDFEmbed'
import ReactAppBlock from '../components/resources/blocks/ReactAppBlock'
import RichTextBlock from '../components/resources/blocks/RichTextBlock'
import ThirdPartyEmbedBlock from '../components/resources/blocks/ThirdPartyEmbedBlock'
import TwoColumnWrapper from '../components/resources/blocks/TwoColumnWrapper'
import VideoEmbedBlock from '../components/resources/blocks/VideoEmbedBlock'
import LearningObjectives from '../components/resources/common/LearningObjectives'
import CTAWidget from '../components/resources/widget/CTAWidget'
import GatedContent from '../components/sanity/GatedContent'
import {getImageSource} from './sanity'
import {getGenericSlug, slugify} from './utils'

export const serializers = {
  block: {
    h2: ({children}) => {
      return (
        <h2 id={children?.[0].key} className="font-nunito font-weight-800">
          {children}
        </h2>
      )
    },
    h3: ({children}) => {
      return (
        <h3 id={children?.[0].key} className="font-nunito font-weight-800">
          {children}
        </h3>
      )
    },
    h4: ({children}) => {
      return (
        <h4 id={children?.[0].key} className="font-nunito font-weight-700">
          {children}
        </h4>
      )
    },
    h5: ({children}) => {
      return (
        <h5 id={children?.[0].key} className="font-nunito font-weight-700">
          {children}
        </h5>
      )
    },
    h6: ({children}) => {
      return (
        <h6 id={children?.[0].key} className="font-nunito font-weight-700">
          {children}
        </h6>
      )
    },
    normal: ({children}) => {
      return (
        <div id={children?.[0].key} className="mb-3">
          {children}
        </div>
      )
    },
    sm: ({children}) => {
      return (
        <div id={children?.[0].key} className="mb-3 text-small">
          {children}
        </div>
      )
    },
    xs: ({children}) => {
      return (
        <div id={children?.[0].key} className="mb-3 text-xs">
          {children}
        </div>
      )
    },
    sectionHeader: ({children}) => {
      return (
        <div>
          <h4>{children}</h4>
          <hr />
        </div>
      )
    },
  },
  types: {
    ctaWidget: CTAWidget,
    table: ({value}) => (
      <div className="table-responsive">
        <table className="table" style={{wordBreak: 'normal'}}>
          <thead>
            <tr>
              {value.rows[0].cells.map((cell, i) => (
                <th key={i} scope="col">
                  {cell}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value.rows.slice(1).map((row) => (
              <tr key={row._key}>
                {row.cells.map((cell, i) => (
                  <td key={`${row._key}_${i}`}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    deprecatedImage: ({value}) => {
      const {_key, url, width, height} = value
      return (
        <ImageComponent
          src={url}
          width={width}
          height={height}
          alt={url}
          fancyboxId={_key}
        />
      )
    },
    linkableImage: ({value}) => {
      return (
        <ImageComponent
          src={getImageSource(value.image).url()}
          width={value.dimensions.width}
          height={value.dimensions.height}
          link={value.link}
          alt={value.title}
          fancyboxId={value._key}
        />
      )
    },
    imageGallery: ({value}) => {
      return <Carousel _key={value._key} images={value.images} />
    },
    highlight: ({value}) => {
      return (
        <div className={`alert alert-${value.color} pb-0`}>
          <PortableText
            value={value.body}
            components={featuredTextSerializers}
          />
        </div>
      )
    },
    reference: ({value}) => {
      const document = value?.unfurledRef
      if (!document?._type) return
      switch (document._type) {
        case 'leadForm':
          return (
            <LeadFormBlock
              formEmbedCode={document.formEmbedCode}
              buttonCTAText={document.buttonCTAText}
              sidebarButtonColor={document.sidebarButtonColor}
              sidebarButtonCopy={document.sidebarButtonCopy}
              sidebarIcon={document.sidebarIcon}
              sidebarIconColor={document.sidebarIconColor}
              sidebarTitle={document.sidebarTitle}
            />
          )
        case 'richText':
          return (
            <RichTextBlock
              icon={document.icon}
              color={document.color}
              iconColor={document.iconColor}
              text={document.text}
            />
          )
        default:
          return null
      }
    },
    blockQuote: ({value}) => {
      return (
        <h4
          className={`font-nunito text-center font-weight-800 ${
            value.size ?? ''
          }`}
        >
          &ldquo;{value.quote}&rdquo;
        </h4>
      )
    },
    flashcards: ({value}) => {
      return <Flashcards {...value} />
    },
    gatedContent: ({value}) => {
      return <GatedContent gatedContent={value} key={value._key} />
    },
    lottieContentBlock: ({value}) => {
      return <TwoColumnWrapper {...value} />
    },
    lottieFile: ({value}) => {
      return <LottieFile {...value} />
    },
    twoColumnContentBlock: ({value}) => {
      return <TwoColumnWrapper {...value} />
    },
    documentEmbed: ({value}) => {
      return (
        <div className="mb-3">
          <a
            href={value.url}
            target="_blank"
            className="text-primary text-decoration-underline"
            rel="noreferrer"
          >
            Download
          </a>
        </div>
      )
    },
    pdfEmbed: ({value}) => {
      return <PDFEmbed url={value.pdfUrl} />
    },
    videoEmbed: ({value}) => {
      return <VideoEmbedBlock url={value.url} id={value.id} />
    },
    learningObjectives: ({value}) => {
      return (
        <LearningObjectives objectives={value.objectives} title={value.title} />
      )
    },
    thirdPartyEmbed: ({value}) => {
      return <ThirdPartyEmbedBlock value={value} />
    },
    reactApp: ({value}) => {
      return <ReactAppBlock value={value} />
    },
    contentGrid: ({value}) => {
      return (
        <ContentGridBlock
          blocks={value.content}
          columnOverride={value.columnOverride}
          textAlignment={value.textAlignment}
        />
      )
    },
    contentItem: ({value}) => {
      return (
        <ContentBlock
          body={value.document.featuredText}
          buttonText={value.document.buttonText}
          containerClass={`flex-lg-${value.imagePlacement === 'right' ? 'row-reverse' : 'row'} align-items-lg-center flex-wrap flex-lg-nowrap mb-5`}
          flexDirection="column"
          image={value.document.featuredImage}
          imageObjectFit="contain"
          link={getGenericSlug(
            value.document._type,
            value.document.slug,
            value.document.publishedAt,
          )}
          linkInNewTab={value.document.blank}
          showButton={value.document.showButton}
          textContainerClass={`ms-0 ${value.imagePlacement === 'right' ? 'me' : 'ms'}-lg-3`}
          title={<h3 className="mb-1">{value.document.title}</h3>}
          useVideoEmbed={value.document.isVideo}
        />
      )
    },
    customAd: ({value}) => {
      return <BannerAd adCode={value.headerCode} />
    },
  },
  marks: {
    link: ({children, value}) =>
      value.blank ? (
        <a
          href={value.href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary text-decoration-underline"
        >
          {children}
        </a>
      ) : (
        <a href={value.href} className="text-primary text-decoration-underline">
          {children}
        </a>
      ),
    internalLink: ({children, value}) => {
      return (
        <Link
          href={value.slug}
          className="text-primary text-decoration-underline"
        >
          {children}
        </Link>
      )
    },
    anchorLink: ({children, value}) => (
      <a href={`#${value.link}`}>{children}</a>
    ),
    anchorId: ({children, value}) => <span id={`${value.id}`}>{children}</span>,
    superscript: ({children}) => {
      return <sup>{children}</sup>
    },
    superduperscript: ({children}) => {
      return (
        <sup>
          <sup>{children}</sup>
        </sup>
      )
    },
    subscript: ({children}) => {
      return <sub>{children}</sub>
    },
    tocHeader: (props) => {
      return <div id={slugify(props.text)}>{props.children}</div>
    },
    tocSubheader: (props) => {
      return <div id={slugify(props.text)}>{props.children}</div>
    },
  },
}

export const featuredTextSerializers = {
  marks: {
    link: (props) =>
      props.value.blank ? (
        <a
          href={props.value.href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary text-decoration-underline"
        >
          {props.children}
        </a>
      ) : (
        <a
          href={props.value.href}
          className="text-primary text-decoration-underline"
        >
          {props.children}
        </a>
      ),
    superscript: ({children}) => <sup>{children}</sup>,
    superduperscript: ({children}) => {
      return (
        <sup>
          <sup>{children}</sup>
        </sup>
      )
    },
    subscript: ({children}) => <sub>{children}</sub>,
  },
}

export default serializers
