import {useEffect, useRef, useState} from 'react'
import {useController} from 'react-hook-form'
import type {Control, DeepRequired, FieldErrorsImpl} from 'react-hook-form'
import Select from 'react-select'
import type {SelectInstance} from 'react-select'

import {
  HS_ROLE_OPTOMETRIST,
  ophthalmologistSpecialtyOptions,
  optometristSpecialtyOptions,
} from '../../core/constants'
import FormError from './FormError'
import {SignUpFormControl} from './SignUpForm'

/**
 * Render a multiselect dropdown of specialties.
 * This component has a dependency on the `role` prop, which determines the
 * dropdown options and the label copy.
 */
const SpecialtiesSelectInput = ({
  control,
  errors,
  required,
  role,
}: {
  control: Control<SignUpFormControl>
  errors: FieldErrorsImpl<DeepRequired<SignUpFormControl>>
  required: boolean
  role: string
}) => {
  const [options, setOptions] = useState<{label: string; value: string}[]>([])
  const [label, setLabel] = useState('')
  const selectInput =
    useRef<SelectInstance<{label: string; value: string}, true>>(null)

  const {
    field: {onChange, onBlur, name, value},
  } = useController({
    name: 'specialties',
    control,
    rules: {required: required ? 'Specialization is required.' : false},
  })

  useEffect(() => {
    // Change the label and dropdown items
    if (role === HS_ROLE_OPTOMETRIST) {
      setLabel('What are your primary area(s) of clinical focus?')
      setOptions(optometristSpecialtyOptions)
    } else {
      setLabel(
        'Did you or will you complete a fellowship in any of these areas?',
      )
      setOptions(ophthalmologistSpecialtyOptions)
    }

    // Reset the dropdown selections
    selectInput.current?.clearValue()
  }, [role])

  return (
    <>
      <label htmlFor="specialties" className="form-label mb-0">
        {label}
        <span className="text-danger">*</span>
      </label>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FormError name="specialties" errors={errors} />
      <Select
        id="specialties"
        ref={selectInput}
        onChange={(optionValue) => {
          optionValue &&
            onChange(optionValue.map((optionValue) => optionValue.value))
        }}
        onBlur={onBlur}
        value={options.filter((specialtyOption) =>
          value.includes(specialtyOption.value),
        )}
        name={name}
        options={options}
        isMulti
      />
    </>
  )
}
export default SpecialtiesSelectInput
