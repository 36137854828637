import {createRef, useCallback, useEffect} from 'react'

import {create} from '@lottiefiles/lottie-interactivity'

import styles from '../../../styles/LottieContentBlock.module.scss'

interface LottieComponentProps {
  _key: string
  animationType: 'sync' | 'normal'
  loop?: boolean
  src: string
}

export default function LottieComponent({
  _key,
  animationType,
  loop = true,
  src,
}: LottieComponentProps) {
  const lottieRef = createRef<HTMLElement | null>()

  const createLottie = useCallback(() => {
    if (animationType === 'sync') {
      create({
        player: `#lottie-${_key}`,
        mode: 'scroll',
        container: `#container-${_key}`,
        actions: [
          {
            visibility: screen.width <= 576 ? [0.2, 1.0] : [0.25, 0.8],
            type: 'seek',
            frames: [0],
          },
        ],
      })
    } else {
      create({
        player: `#lottie-${_key}`,
        mode: 'scroll',
        actions: [
          {
            visibility: [0.3, 1.0],
            type: loop ? 'loop' : 'playOnce',
            frames: [0],
          },
        ],
      })
    }
  }, [_key, animationType, loop])

  useEffect(() => {
    import('@lottiefiles/lottie-player')

    let lottieRefValue: HTMLElement | null = null
    if (lottieRef.current) {
      lottieRef.current.addEventListener('load', createLottie)
      lottieRefValue = lottieRef.current
    }

    return () => {
      if (lottieRefValue)
        lottieRefValue.removeEventListener('load', createLottie)
    }
  }, [createLottie, lottieRef])

  return (
    <lottie-player
      ref={lottieRef}
      mode="normal"
      background="transparent"
      speed="1"
      id={`lottie-${_key}`}
      src={src}
      className={`${styles.lottieAnimation}`}
    />
  )
}
