import Script from 'next/script'

import {ANALYTICS_ENABLED, LINKEDIN_PARTNER_ID} from './config'

// https://www.linkedin.com/help/lms/answer/a418880/add-the-linkedin-insight-tag-to-your-website?lang=en-us&intendedLocale=en
export const trackLinkedInEvent = (id: number) => {
  // for LinkedIn Insight Tag event-specific conversion tracking
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window.lintrk('track', {conversion_id: id})
  } catch (e) {
    // continue regardless of error
  }
}

export function LinkedInInit() {
  return (
    <>
      <Script
        id="li-init-partner-id"
        type="text/plain"
        data-cookieconsent="marketing"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            _linkedin_partner_id = "${LINKEDIN_PARTNER_ID}";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `,
        }}
      />
      <Script
        id="li-init"
        type="text/plain"
        data-cookieconsent="marketing"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(l) {
              if (!l) {
                window.lintrk = function(a,b) {
                  window.lintrk.q.push([a,b])
                };
                window.lintrk.q=[]
              }
              var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);
            })(window.lintrk);
          `,
        }}
      />
    </>
  )
}

export function LinkedInPixel() {
  return (
    <noscript>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        height="1"
        width="1"
        style={{display: 'none'}}
        alt=""
        data-cookieconsent="marketing"
        data-cookieblock-src={`https://px.ads.linkedin.com/collect/?pid=${LINKEDIN_PARTNER_ID}&fmt=gif`}
      />
    </noscript>
  )
}
