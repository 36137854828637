import {z} from 'zod'

import {
  SanityBlock,
  SanityImage,
  SanityImageWithDimensions,
  StickyFooterSchema,
} from '../../core/schemas'

export const OrganizationSchema = z.object({
  _id: z.string(),
  _type: z.literal('organization').optional(),
  _rev: z.string().optional(),
  _createdAt: z.string().optional(),
  _updatedAt: z.string().optional(),
  name: z.string(),
  slug: z.object({
    current: z.string(),
  }),
  uuid: z.string().optional().nullable(),
  image: SanityImage.optional().nullable(),
  description: z.array(SanityBlock).optional().nullable(),
  website: z.string().url().optional(),
  socialLinks: z.array(z.string()).optional(),
})

export const OrganizationPageSchema = z.object({
  _createdAt: z.string().optional(),
  _id: z.string(),
  _rev: z.string(),
  _type: z.literal('organizationPage'),
  _updatedAt: z.string(),
  title: z.string(),
  slug: z.string(),
  organization: OrganizationSchema.optional().nullable(),
  templateType: z.string(),
  banner: z
    .object({
      body: z.array(SanityBlock).nullish(),
      heading: z.array(SanityBlock).nullish(),
      logo: SanityImageWithDimensions.nullish(),
    })
    .nullish(),
  body: z.array(SanityBlock),
  stickyFooter: StickyFooterSchema.nullish(),
})

export const CustomSponsorSchema = OrganizationSchema.pick({
  name: true,
  image: true,
  website: true,
})
const CustomSponsorsSchema = z.array(CustomSponsorSchema)
export type CustomSponsors = z.infer<typeof CustomSponsorsSchema>
