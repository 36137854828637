import Head from 'next/head'

import OutboundLinks from '../head/OutboundLinks'

export default function SiteMeta({
  title = 'Eyes On Eyecare | #1 in Optometry & Ophthalmology Education',
}) {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta property="og:site_name" content="Eyes On Eyecare" />
        <meta
          property="og:image"
          content="https://cdn.sanity.io/images/51cpf7jm/production/995f50feee31e7513134948fae997b29f2f839c7-1200x628.png"
          key="og:image"
        />
        <meta
          name="description"
          key="description"
          content="A digital eyecare publication providing clinical and career education to the young generation of optometrists and ophthalmologists."
        />
        <title>{title}</title>
      </Head>
      <OutboundLinks />
    </>
  )
}
