import {useContext} from 'react'

import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {AuthContext, useUser} from '../../core/auth'
import {ResourceComponentContext} from '../resources/ResourceComponent'

export const LikeButton = () => {
  const {likeResource, unlikeResource, hasLiked} = useContext(
    ResourceComponentContext,
  )
  const {openAuthModal} = useContext(AuthContext)
  const {user} = useUser()
  const handleClick = () => {
    if (!user) {
      openAuthModal()
      return
    }
    if (hasLiked) {
      unlikeResource()
    } else {
      likeResource()
    }
  }

  return (
    <button
      onClick={handleClick}
      className={`btn d-flex ${
        hasLiked ? 'btn-primary' : 'btn-outline-primary'
      } me-2 btn-md d-flex justify-content-center align-items-center py-1 flex-grow-1 flex-lg-grow-0`}
    >
      <FontAwesomeIcon icon={faThumbsUp} className="me-0 me-sm-2" size="lg" />
      <span className="d-none d-sm-block">Like</span>
    </button>
  )
}
