export default function JobsDropdown() {
  return (
    <span className="nav-item dropdown me-3 text-uppercase">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="jobsDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Jobs
      </a>
      <div className="dropdown-menu" aria-labelledby="jobsDropdown">
        <a
          className="dropdown-item"
          href="https://jobs.eyesoneyecare.com/jobs/eyecare/"
        >
          Search Eyecare Jobs
        </a>
        <a
          className="dropdown-item"
          href="https://jobs.eyesoneyecare.com/eyecare-hiring-products/"
        >
          Hiring Services for Employers
        </a>
      </div>
    </span>
  )
}
