import Link from 'next/link'

import {PortableText} from '@portabletext/react'
import type {PortableTextBlock} from '@portabletext/types'
import {z} from 'zod'

import {getImageSource} from '../../core/sanity'
import {SanityImage} from '../../core/schemas'
import {featuredTextSerializers} from '../../core/serializers'
import FancyboxWrapper from '../images/FancyboxWrapper'
import {FancyboxImage} from '../images/ImageComponent'
import SanityImageComponent from '../sanity/SanityImage'

interface ContentBlockProps {
  image: z.infer<typeof SanityImage> | null
  imageWidth: number
  imageAspectRatio: number
  imagePriority?: boolean
  imageObjectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  showImage: boolean
  hideMobileImage: boolean
  heading: string
  body: PortableTextBlock[] | null
  footer: string | null
  subfooter: string | null
  link: string | null
  linkInNewTab?: boolean
  showButton?: boolean
  buttonText?: string
  flexDirection: string | null
  flexFooterDirection: string | null
  containerClass: string
  textContainerClass?: string
  useVideoEmbed?: boolean
}

export default function ContentBlock({
  image,
  // 1.91 aspect ratio (1200x628 per sanity images)
  imageWidth = 600,
  imageAspectRatio = 157 / 300,
  imageObjectFit = 'fill',
  imagePriority = false,
  showImage = true,
  hideMobileImage = false,
  heading,
  title,
  body,
  footer,
  subfooter,
  link,
  linkInNewTab = false,
  showButton,
  buttonText,
  flexDirection = 'row',
  flexFooterDirection = 'column',
  containerClass = 'mb-4',
  textContainerClass,
  useVideoEmbed = false,
}: Partial<ContentBlockProps> & {title: JSX.Element}) {
  return (
    <div
      className={`d-flex flex-${flexDirection} ${containerClass} position-relative`}
    >
      {useVideoEmbed
        ? link && (
            <div className="flex-shrink-0">
              <FancyboxImage
                id={`${link}-image`}
                src={getImageSource(image?.asset).url()}
                videoSrc={link}
                width={imageWidth}
                height={imageWidth * imageAspectRatio}
                priority={imagePriority}
                objectFit={imageObjectFit}
              />
            </div>
          )
        : showImage && (
            <div
              style={{minWidth: '80px'}}
              className={`${
                hideMobileImage && 'd-none d-lg-block'
              } flex-shrink-0`}
            >
              <SanityImageComponent
                src={image?.asset}
                alt={title.props.children}
                width={imageWidth}
                height={imageWidth * imageAspectRatio}
                className="rounded"
                objectFit={imageObjectFit}
                priority={imagePriority}
              />
            </div>
          )}
      <div
        className={`${
          textContainerClass ??
          (flexDirection === 'row'
            ? `${hideMobileImage ? 'ms-0 ms-lg-3' : 'ms-3'}`
            : '')
        }`}
      >
        {heading && (
          <div
            className={`block-label-primary ${
              flexDirection === 'column' && 'my-2'
            }`}
          >
            {heading}
          </div>
        )}
        {link ? (
          <Link href={link} legacyBehavior>
            {linkInNewTab ? (
              <a
                className="stretched-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
              </a>
            ) : (
              <a className={`${useVideoEmbed ? '' : 'stretched-link'}`}>
                {title}
              </a>
            )}
          </Link>
        ) : (
          title
        )}
        {body && (
          <div className="text-muted mt-2">
            <PortableText value={body} components={featuredTextSerializers} />
          </div>
        )}
        <div
          className={`d-flex ${
            flexFooterDirection === 'row'
              ? 'flex-row align-items-center'
              : 'flex-column'
          }`}
        >
          {footer && <small className="fw-semibold me-3">{footer}</small>}
          {subfooter && (
            <small className="text-capitalize text-muted">{subfooter}</small>
          )}
        </div>
        {showButton && link && (
          <div>
            {useVideoEmbed ? (
              <FancyboxWrapper id={`${link}-button`} src={link}>
                <button className="btn btn-primary">{buttonText}</button>
              </FancyboxWrapper>
            ) : (
              <Link href={link} legacyBehavior>
                {linkInNewTab ? (
                  <a
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {buttonText}
                  </a>
                ) : (
                  <a className="btn btn-primary">{buttonText}</a>
                )}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
