import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

// Static build + back-end use
export const backendClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-08-31',
  token: process.env.SANITY_API_TOKEN,
  useCdn: false,
})

// Front-end use (default)
export const cdnClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-08-31',
  useCdn: true,
})

export const sanity = cdnClient

const imageBuilder = imageUrlBuilder(sanity)
/**
 * Given a Sanity Image, return a URL Builder to use as the `src` attribute in
 * an image tag
 * @param source A Sanity Image
 * @returns {ImageUrlBuilder}
 */
export const getImageSource = (source, backup = null) => {
  const backupImage =
    'https://cdn.sanity.io/images/04gtv61o/dev/ab4460cafc30a6aa796c880c7de6b1ae4ed5c269-1200x628.png'
  return imageBuilder.image(source ?? backup ?? backupImage)
}

export const SanityUrl =
  process.env.NODE_ENV === 'production'
    ? `${process.env.VERCEL_URL}/resources/`
    : 'http://localhost:3000/resources/'

/**
 * Transform Sanity Portable Text to a basic string
 * From https://www.sanity.io/docs/presenting-block-text#ac67a867dd69
 *
 * @param {array} blocks - The sanity portable text to transform
 * @returns string
 */
export const portableTextToString = (blocks = []) => {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  )
}

export const getStudioUrl = () => {
  const env = process.env.NODE_ENV
  if (env === 'development') {
    return 'http://localhost:3333'
  }
  return 'https://eoe1.sanity.studio'
}

export const sanityAPI = 'https://api.sanity.io/v1'
