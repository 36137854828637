import Link from 'next/link'

import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import useHasMounted from '../../core/hooks/useHasMounted'
import {displayPublishedDate} from '../../core/utils'
import {ResourceProjectionSchema} from '../resources/schemas'
import SanityImage from '../sanity/SanityImage'

const HoverCardPropsSchema = z.object({
  body: ResourceProjectionSchema.shape.body,
  colClass: z.string().optional(),
  heading: z.string().optional(),
  hideMobileImage: z.boolean().optional(),
  image: ResourceProjectionSchema.shape.featuredImage,
  subheading: z.string().optional(),
  title: z.string(),
  url: z.string(),
})

export default function HoverCard({
  body,
  colClass = 'col-xs-12 col-sm-4',
  heading,
  hideMobileImage = false,
  image,
  subheading,
  title,
  url,
}: z.infer<typeof HoverCardPropsSchema>) {
  const hasMounted = useHasMounted()

  if (!title) {
    return
  }

  return (
    <div className={`${colClass} mt-md-0 my-3`}>
      <Link
        href={url}
        className="text-decoration-none card h-100 shadow-sm hover-lift"
      >
        <div className={`${hideMobileImage && 'd-none'} d-lg-block`}>
          <SanityImage
            src={image}
            alt={title}
            width={600}
            height={300}
            className="card-img-top border-bottom"
          />
        </div>
        <div className="card-body pt-3">
          {heading && (
            <span className="mb-0 blocked text-small text-primary">
              {heading}
            </span>
          )}
          {subheading && hasMounted && (
            <small className="p-0 mb-2 d-block text-muted">
              <time dateTime={subheading}>
                {displayPublishedDate(subheading)}
              </time>
            </small>
          )}
          <span className={`p-0 sans-serif ${!body && 'm-0'}`}>
            <strong>{title}</strong>
          </span>
          {body && (
            <div className="text-small text-muted mb-2">
              <PortableText value={body} />
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}
