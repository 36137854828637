import {useContext, useEffect} from 'react'

import {ResourceComponentContext} from '../ResourceComponent'

// Lead form blocks just load hubspot js and append a form to the page
// by calling some hubspot functions
// https://community.hubspot.com/t5/APIs-Integrations/HELP-Embed-a-form-in-a-React-component/m-p/230042
function LeadFormBlock({formEmbedCode}) {
  const {resourceIndex} = useContext(ResourceComponentContext)
  const leadFormId = `leadForm-${resourceIndex}`

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt && formEmbedCode) {
        const embed = JSON.parse(formEmbedCode)
        embed['target'] = `#${leadFormId}`
        window.hbspt.forms.create(embed)
      }
    })
  }, [formEmbedCode, leadFormId])

  return <div id={leadFormId} className="leadForm" />
}

export default LeadFormBlock
