import {useCallback} from 'react'

import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import {SanityBlock} from '../../../core/schemas'
import serializers, {featuredTextSerializers} from '../../../core/serializers'
import styles from '../../../styles/LottieContentBlock.module.scss'
import LottieComponent from './LottieComponent'

const TwoColumnContentBlockSchema = z.object({
  _key: z.string(),
  _type: z.literal('twoColumnContentBlock'),
  heading: z.array(SanityBlock),
  body: z.array(SanityBlock),
  content: z.array(SanityBlock),
  verticalAlign: z.string().optional(),
})

const LottieContentBlockSchema = TwoColumnContentBlockSchema.omit({
  content: true,
}).extend({
  _type: z.literal('lottieContentBlock'),
  lottieUrl: z.string(),
  animationType: z.enum(['sync', 'normal']),
})

const TwoColumnWrapperSchema = z.union([
  TwoColumnContentBlockSchema,
  LottieContentBlockSchema,
])

export default function TwoColumnWrapper(
  props: z.infer<typeof TwoColumnWrapperSchema>,
) {
  const {_key, heading, body, verticalAlign} = props
  const getColumnContent = useCallback(() => {
    switch (props._type) {
      case 'lottieContentBlock':
        return (
          <LottieComponent
            _key={_key}
            animationType={props.animationType}
            src={props.lottieUrl}
          />
        )
      case 'twoColumnContentBlock':
        return (
          <div className="text-body flex-grow-1">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore: TODO: convert serializers to typescript */}
            <PortableText value={props.content} components={serializers} />
          </div>
        )
    }
  }, [_key, props])

  return (
    <div className="lottie-content-block d-flex justify-content-between align-items-center my-5">
      <div className={`row align-items-${verticalAlign ?? 'start'}`}>
        <div className="col-md order-2 order-md-1">
          <div id={`container-${_key}`}>
            <div className={`${styles.lottieCopy} pr-lg-5`}>
              {heading && (
                <div className="h2 font-nunito fw-bold">
                  <PortableText
                    value={heading}
                    components={featuredTextSerializers}
                  />
                </div>
              )}
              {body && (
                <div>
                  <PortableText
                    value={body}
                    components={featuredTextSerializers}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.stickyLottie} col-md order-1 order-md-2`}>
          <div className={` d-flex justify-content-center align-items-center`}>
            {getColumnContent()}
          </div>
        </div>
      </div>
    </div>
  )
}
