import {faChevronLeft, faChevronRight} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {z} from 'zod'

import {getImageSource} from '../../core/sanity'
import {SanityImage} from '../../core/schemas'
import styles from '../../styles/modules/Carousel.module.scss'
import {FancyboxImage} from './ImageComponent'

const ImageSchema = z.object({
  _key: z.string(),
  image: SanityImage,
  altText: z.string(),
  caption: z.string().optional(),
})

const CarouselProps = z.object({
  _key: z.string(),
  images: z.array(ImageSchema),
})

export default function Carousel({
  _key,
  images,
}: z.infer<typeof CarouselProps>) {
  const width = 640
  const carouselId = `carousel_${_key}`

  const carouselButtonStyles =
    'rounded-circle bg-white shadow d-flex justify-content-center align-items-center'

  return (
    <div
      id={carouselId}
      className={`carousel slide my-4 ${styles.mainCarousel}`}
      data-bs-ride="carousel"
      style={{maxWidth: `${width}px`}}
    >
      {/* TODO: add additional button indicators */}
      {/* <div className={`${styles.carouselIndicators}`}>
        {imageComponents.map((image, i) => (
          <div
            key={i}
            role="button"
            data-bs-target={`#${carouselId}`}
            data-bs-slide-to={i}
            className={`${i === 0 ? 'active' : ""}`}
            aria-current="true"
            aria-label={`Slide ${i + 1}`}>
            {image}
          </div>
        ))}
      </div> */}

      <div className="carousel-inner">
        {images.map(({_key, image, altText, caption}, i) => (
          <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
            <FancyboxImage
              key={_key}
              src={getImageSource(image.asset).url()}
              width={width}
              height={(width * 9) / 16}
              alt={altText}
              caption={caption}
              id={carouselId}
              objectFit="contain"
            />
          </div>
        ))}
      </div>
      <button
        className={`carousel-control-prev ${styles.prevButton} ${styles.growOnHover}`}
        type="button"
        data-bs-target={`#${carouselId}`}
        data-bs-slide="prev"
      >
        <div className={`${carouselButtonStyles} ${styles.carouselButton}`}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={`text-dark ${styles.chevronIcon}`}
            size="lg"
            aria-hidden="true"
          />
        </div>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className={`carousel-control-next ${styles.nextButton} ${styles.growOnHover}`}
        type="button"
        data-bs-target={`#${carouselId}`}
        data-bs-slide="next"
      >
        <div className={`${carouselButtonStyles} ${styles.carouselButton}`}>
          <FontAwesomeIcon
            icon={faChevronRight}
            className={`text-dark ${styles.chevronIcon}`}
            size="lg"
            aria-hidden="true"
          />
        </div>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}
