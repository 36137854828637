import {useContext} from 'react'

import ArticleSchema from '../../head/ArticleSchema'
import StickyFooter from '../../layouts/StickyFooter'
import EditInSanityButton from '../../sanity/EditInSanityButton'
import {ResourceComponentContext} from '../ResourceComponent'
import ResourceInReadAds from '../ads/ResourceInReadAds'
import ResourceBody from '../common/Body'
import MobileStickyMenu from '../common/MobileStickyMenu'
import RecommendedResources from '../common/RecommendedResources'
import ResourceFooter from '../common/ResourceFooter'
import ResourceHeader from '../common/ResourceHeader'
import Sidebar from '../common/Sidebar'

export default function DefaultTemplate() {
  const {resource} = useContext(ResourceComponentContext)
  return (
    <>
      <ResourceInReadAds />
      <div className="container">
        <EditInSanityButton sanityId={resource._id} sanityType="resourcePage" />
        <div className="row">
          <div className="col-lg-8">
            <ResourceHeader />
            <ResourceBody />
            <ResourceFooter />
            <RecommendedResources />
          </div>
          <div className="col-lg-4 mt-3 mt-lg-0">
            <Sidebar />
          </div>
        </div>
        {resource.stickyFooter && (
          <StickyFooter stickyFooter={resource.stickyFooter} />
        )}
        <MobileStickyMenu />
        <ArticleSchema />
      </div>
    </>
  )
}
