import type {Dispatch, SetStateAction} from 'react'
import {useForm} from 'react-hook-form'
import type {FieldValues} from 'react-hook-form'

import {useRouter} from 'next/router'

import {useMutation} from '@tanstack/react-query'

import type {
  SignInUserData,
  SignInUserError,
  SignInUserVariables,
} from '../../core/api'
import {signInUser} from '../../core/api'
import {
  MIXPANEL_EVENT_SUBMITTED_SIGN_IN_FORM,
  MIXPANEL_JOURNEY_SIGN_UP_PAGE,
  MIXPANEL_PLATFORM,
  mixpanel,
} from '../analytics/mixpanel'
import {getDocumentTitle} from '../analytics/utils'
import type {AuthenticationFormState} from './AuthenticationForm'
import FormError from './FormError'

export default function SignInForm({
  redirectTo,
  setFormState,
  submitButtonText,
}: {
  redirectTo?: string
  setFormState: Dispatch<SetStateAction<AuthenticationFormState>>
  submitButtonText?: string
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm()
  const buttonText = submitButtonText ?? 'Sign In'
  const router = useRouter()
  const {mutate, isLoading} = useMutation<
    SignInUserData,
    SignInUserError,
    SignInUserVariables
  >((data) => signInUser(data), {
    onSuccess: () => {
      setFormState('success')
      mixpanel.track(MIXPANEL_EVENT_SUBMITTED_SIGN_IN_FORM, {
        Journey: MIXPANEL_JOURNEY_SIGN_UP_PAGE,
        Title: getDocumentTitle(),
        Platform: MIXPANEL_PLATFORM,
      })
    },
    onError: (error) =>
      setError('email', {type: 'custom', message: error.message}),
  })

  const onSubmit = (data: FieldValues) => {
    mutate({
      email: data.email,
      next: (router.query?.next as string) || redirectTo,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Email */}
      <FormError name="email" errors={errors} />
      <div className="input-group mb-4">
        <input
          required
          type="email"
          placeholder="Email address"
          id="email"
          className="form-control form-control-lg"
          {...register('email', {required: 'Email address is required'})}
        />

        <button
          className="btn btn-lg btn-primary"
          type="submit"
          disabled={isLoading}
        >
          {buttonText}
        </button>
      </div>
    </form>
  )
}
