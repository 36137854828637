import {useContext} from 'react'

import {faBookmark} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {AuthContext, useUser} from '../../core/auth'
import {ResourceComponentContext} from '../resources/ResourceComponent'

export const BookmarkButton = () => {
  const {openAuthModal} = useContext(AuthContext)
  const {hasBookmarked, bookmarkResource, unbookmarkResource} = useContext(
    ResourceComponentContext,
  )
  const {user} = useUser()
  const handleClick = () => {
    if (!user) {
      openAuthModal()
      return
    }
    if (hasBookmarked) {
      unbookmarkResource()
    } else {
      bookmarkResource()
    }
  }
  return (
    <button
      onClick={handleClick}
      className={`btn d-flex ${
        hasBookmarked ? 'btn-primary' : 'btn-outline-primary'
      } me-2 btn-md d-flex justify-content-center align-items-center py-1 flex-grow-1 flex-lg-grow-0`}
    >
      <FontAwesomeIcon icon={faBookmark} className="me-0 me-sm-2" size="lg" />
      <span className="d-none d-sm-block">Save</span>
    </button>
  )
}
