import {trackFacebookCustomEvent} from '../facebook'
import {trackGoogleEvent} from '../google'
import {
  MIXPANEL_EVENT_STARTED_SIGN_UP_FORM,
  MIXPANEL_JOURNEY_GATED_CONTENT_FORM,
  MIXPANEL_JOURNEY_SIGN_UP_PAGE,
  MIXPANEL_PLATFORM,
  mixpanel,
} from '../mixpanel'
import {getDocumentTitle} from '../utils'

export function trackStartedSignUp(gatedContentSlug?: string) {
  // This function makes API calls to third party platforms for conversion tracking and analytics purposes.
  const page = gatedContentSlug ? 'resource-page' : 'sign-up-page'
  const eventName = gatedContentSlug
    ? 'Started Gated Content Form'
    : 'Started Sign Up Form'
  const journey = gatedContentSlug
    ? MIXPANEL_JOURNEY_GATED_CONTENT_FORM
    : MIXPANEL_JOURNEY_SIGN_UP_PAGE

  const mixpanelProps: {
    Journey:
      | typeof MIXPANEL_JOURNEY_GATED_CONTENT_FORM
      | typeof MIXPANEL_JOURNEY_SIGN_UP_PAGE
    Platform: typeof MIXPANEL_PLATFORM
    Title?: string
    'Resource Slug'?: string
  } = {
    Journey: journey,
    Title: getDocumentTitle(),
    Platform: MIXPANEL_PLATFORM,
  }

  if (gatedContentSlug) {
    mixpanelProps['Resource Slug'] = gatedContentSlug
  }

  mixpanel.track(MIXPANEL_EVENT_STARTED_SIGN_UP_FORM, mixpanelProps)
  trackGoogleEvent('sign-up', {
    event_label: page,
  })
  trackFacebookCustomEvent(eventName, {
    page,
  })
}
