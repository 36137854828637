import {useEffect} from 'react'

export const SmartRecognitionInit = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://portal.smartrecognition.com/js/libcode3.js'
    script.type = 'text/plain'
    script.async = true
    script.dataset.cookieconsent = 'marketing'

    document.body.appendChild(script)

    window._avp = window._avp || []
    if (!document.cookie || document.cookie.indexOf('AVPDCAP=') === -1) {
      window._avp.push({
        tagid: 'xYCK1usFNvbEFahUEdy0',
        alias: '/',
        type: 'dynamic',
        zid: 7735,
        pid: 5246,
        secure: true,
      })
    }
  }, [])

  return <div data-tagid="xYCK1usFNvbEFahUEdy0"></div>
}
