import Image from 'next/legacy/image'

import {getFormattedImage} from '../../core/utils'

export default function SanityImage({
  src,
  alt,
  width,
  height,
  className = '',
  objectFit = 'cover',
  priority = false,
  style = {},
}) {
  return (
    <Image
      src={getFormattedImage(src, width * 2)}
      alt={alt}
      width={width}
      height={height}
      className={className}
      objectFit={objectFit}
      layout="intrinsic"
      priority={priority}
      style={style}
    />
  )
}
