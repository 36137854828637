import {useContext} from 'react'

import {getImageSource} from '../../core/sanity'
import {ResourceComponentContext} from '../resources/ResourceComponent'

export default function ArticleSchema() {
  const {resource} = useContext(ResourceComponentContext)
  function addldJson() {
    return {
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `https://eyesoneyecare.com/resources/${resource.slug}/`,
        },
        author: [
          resource.authors &&
            resource.authors.map((author) => {
              return {
                '@type': 'Person',
                name: author.name,
                url: `https://eyesoneyecare.com/authors/${author.slug}/`,
              }
            }),
        ],
        headline: resource.title,
        image: resource.featuredImage
          ? [getImageSource(resource.featuredImage).url()]
          : [],
        datePublished: resource.publishedAt,
        publisher: {
          '@type': 'Organization',
          name: 'Eyes On Eyecare',
          logo: {
            '@type': 'ImageObject',
            url: 'https://covalentcareers3.s3.amazonaws.com/media/images/eo/logo%402x.png',
          },
        },
      }),
    }
  }
  return (
    <script
      type="application/ld+json"
      key="article-jsonld"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={addldJson()}
    />
  )
}
