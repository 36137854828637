import React, {useContext} from 'react'

import {ResourceComponentContext} from '../ResourceComponent'

const AccessContentButton: React.FC = (props) => {
  const {resource} = useContext(ResourceComponentContext)
  return (
    <button
      onClick={() => {
        const gatedContent = document.querySelector(
          `.gated-content[data-resource-slug="${resource.slug}"]`,
        )
        if (gatedContent) {
          gatedContent.scrollIntoView({behavior: 'smooth'})
        }
      }}
      {...props}
    >
      Access Content
    </button>
  )
}
export default AccessContentButton
