import Link from 'next/link'

import {useUser} from '../../core/auth'
import {getStudioUrl} from '../../core/sanity'

const EditInSanityButton = ({
  sanityType,
  sanityId,
  primary = true,
  margin = 'mb-3',
}) => {
  const {user, isAuthenticating} = useUser()
  const url = `${getStudioUrl()}/desk/${sanityType};${sanityId}`

  if (isAuthenticating || !user?.is_staff) return <></>
  return (
    <Link
      href={url}
      target="_blank"
      className={`btn ${
        primary ? 'btn-primary' : 'btn-outline-secondary'
      } text-white font-aesthet text-xl py-1 ${margin}`}
    >
      Edit
    </Link>
  )
}
export default EditInSanityButton
