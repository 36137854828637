import {useEffect, useState} from 'react'

import Image from 'next/legacy/image'
import {useRouter} from 'next/router'

import {baseUrl} from '../../core/constants'
import AuthenticationForm from '../forms/AuthenticationForm'

export const AuthModal = () => {
  const [redirectTo, setRedirectTo] = useState<string>(baseUrl)
  const {asPath} = useRouter()

  // Redirect to the specified redirect page or home page after sign in
  useEffect(() => {
    const host = window.location.host
    const protocol = window.location.protocol
    setRedirectTo(`${protocol}//${host}${asPath}`)
  }, [asPath])

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Image
          alt="Eyes On Eyecare logo"
          src="/logos/eoe-logo.png"
          width={125}
          height={40}
        />
      </div>
      <h3 id="subscribe" className="text-center">
        Subscribe to Eyes On Eyecare Now!
      </h3>
      <AuthenticationForm
        displayFormTitle={false}
        initialState="signUp"
        redirectTo={redirectTo}
        successMessage="Check your email and follow the link to sign in!"
        pageTitle={document.title}
      />
    </div>
  )
}
