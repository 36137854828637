import {useContext} from 'react'

import {
  faBellOn,
  faHospital,
  faNewspaper,
  faSearch,
  faSparkles,
} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {useUser} from '../../../core/auth'
import {ResourceComponentContext} from '../ResourceComponent'
import AccessContentButton from './AccessContentButton'

function MenuItem({href, icon, text}) {
  return (
    <div className="d-flex flex-column justify-content-around align-items-center">
      <a
        href={href}
        className="d-flex flex-column justify-content-around align-items-center"
      >
        <div style={{width: '17px', height: '17px'}} className="mb-1">
          <FontAwesomeIcon icon={icon} className="text-primary" />
        </div>
        <span className="text-small">{text}</span>
      </a>
    </div>
  )
}

export default function MobileStickyMenu() {
  const {leadForm, isVisible, resourceIndex, resource} = useContext(
    ResourceComponentContext,
  )
  const {user, isLoading: isAuthenticating} = useUser()
  const leadFormId = `leadForm-${resourceIndex}`
  if (!isVisible) return <></>
  return (
    <div
      className="d-md-none fixed-bottom d-flex justify-content-around align-items-center bg-white border-top shadow-lg"
      style={{height: '60px'}}
    >
      {leadForm && (
        <div className="w-100 px-2">
          {leadForm.unfurledRef.buttonUrl ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={leadForm.unfurledRef.buttonUrl}
              className="btn btn-primary btn-block w-100"
            >
              {leadForm.unfurledRef.buttonCTAText}
            </a>
          ) : (
            <a
              href={`#${leadFormId}`}
              className="btn btn-primary btn-block w-100"
            >
              {leadForm.unfurledRef.buttonCTAText}
            </a>
          )}
        </div>
      )}
      {/* Display an Access Content button to move the user towards gated content */}
      {!leadForm && resource.containsGatedContent && (
        <div className="w-100 px-2">
          <AccessContentButton className="btn btn-primary btn-block w-100" />
        </div>
      )}
      {!leadForm && !resource.containsGatedContent && (
        <>
          <MenuItem href="/resources/" icon={faSearch} text="Search" />
          {!isAuthenticating && !user && (
            <MenuItem href="/sign-up/" icon={faBellOn} text="Subscribe" />
          )}
          {!isAuthenticating && user && (
            <MenuItem
              href="https://glance.eyesoneyecare.com/"
              icon={faNewspaper}
              text="News"
            />
          )}
          <MenuItem
            href="https://jobs.eyesoneyecare.com/jobs/"
            icon={faHospital}
            text="Jobs"
          />
          <MenuItem
            href="https://events.eyesoneyecare.com/"
            icon={faSparkles}
            text="Events"
          />
        </>
      )}
    </div>
  )
}
