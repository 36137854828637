import {RatingWidget} from '../../buttons/RatingWidget'
import AuthorBio from './AuthorBio'
import {SocialButtons} from './SocialButtons'

export default function ResourceFooter() {
  return (
    <div>
      <AuthorBio />
      <RatingWidget />
      <div className="d-flex d-lg-none mt-4">
        <SocialButtons />
      </div>
    </div>
  )
}
