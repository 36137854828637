import {useContext, useEffect, useState} from 'react'

import {ResourceComponentContext} from '../../resources/ResourceComponent'
import {AdRollResourcePagePixels} from '../adroll'
import {ANALYTICS_ENABLED} from '../config'
import {trackViewResource} from '../tracking/trackViewResource'

export default function ResourceAnalytics() {
  const {resource} = useContext(ResourceComponentContext)

  const [analyticsFired, setAnalyticsFired] = useState(false)

  useEffect(() => {
    if (ANALYTICS_ENABLED && !analyticsFired && resource) {
      trackViewResource({onSuccess: () => setAnalyticsFired(true), resource})
    }
  }, [analyticsFired, resource])

  // Adroll tracking is the only analytics that doesn't involve functions so its component still needs to be rendered
  return ANALYTICS_ENABLED ? <AdRollResourcePagePixels /> : null
}
