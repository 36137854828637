import {RefObject, useEffect, useState} from 'react'

export const useIsInViewport = (ref: RefObject<HTMLElement>) => {
  const [isInViewport, setIsInViewport] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) {
          setIsInViewport(entry.isIntersecting)
        }
      },
      {threshold: 0.5},
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [ref])
  return isInViewport
}
