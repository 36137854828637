import {createContext, useContext} from 'react'

import type {Block} from '@sanity/types'

import type {ResourceProjection} from '../../components/resources/schemas'

interface ContextProps {
  resource: ResourceProjection
  preview: boolean
  resourceIndex: number
  /**
   *  Parsed from Sanity in getStaticProps added to context here
   *  so that it can be used across different components in the resource page,
   *  like the sidebar, mobile sticky menu, and the resource body.
   */
  leadForm?: Block
  /**
   * Parsed from Sanity in getStaticProps added to context
   * so that it can be used across different components in the resource page.
   */
  tableOfContents?: ResourceTableOfContents[]
  /**
   * Used to determine whether the resource is the main content
   * being viewed when there are multiple resources on the page. It is used to
   * determine whether to show "singleton" components that we only want one
   * instance of per page rather than per resource. (e.g. the mobile menu)
   */
  isVisible: boolean
}

export const ResourceContext = createContext({} as ContextProps)

export const useResource = () => {
  return useContext(ResourceContext)
}

/**
 * Lead forms need to be in useResource context because they are displayed in
 * the resource page body and in the sidebar component.
 *
 * Iterate through a resource page body to see if there is a lead form
 * embedded. If so, return it.
 *
 * @param resource
 * @returns {*}
 */
export const parseLeadFormFromResource = (resource: ResourceProjection) => {
  let leadForm
  resource?.body?.every((block) => {
    if (block._type === 'reference') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (block.unfurledRef?._type === 'leadForm') {
        leadForm = block
        return false
      }
    }
    return true
  })
  return leadForm
}

export interface ResourceTableOfContents {
  title: string
  id: string
  subsections: {
    title: string
    id: string
  }[]
}

export const parseTableOfContents = (resource: ResourceProjection) => {
  type tocBlock = {
    title: string
    id: string
    subsections: Array<{title: string; id: string}>
  }
  type tocSubsection = {
    title: string
    id: string
    subsections: Array<{title: string; id: string}>
  }
  const toc: Array<tocBlock> = []
  let section: tocSubsection | null = null
  let writeNewSection = false
  resource.body?.forEach((block) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    block?.children?.forEach((child) => {
      const {marks} = child
      if (marks?.includes('tocHeader')) {
        if (writeNewSection && section) {
          toc.push(section)
        }
        section = {
          title: child.text,
          id: child._key,
          subsections: [],
        }
        writeNewSection = true
      }
      if (marks?.includes('tocSubheader') && section) {
        section.subsections.push({
          title: child.text,
          id: child._key,
        })
      }
    })
  })
  if (writeNewSection && section) {
    toc.push(section)
  }
  return toc
}
