import {FieldPath, useController} from 'react-hook-form'
import type {Control} from 'react-hook-form'
import Select from 'react-select'

import {SignUpFormControl} from './SignUpForm'

export const options = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
]

/**
 * Render a single select dropdown of eyecare roles.
 */
export default function BooleanSelectInput({
  control,
  inputName,
}: {
  control: Control<SignUpFormControl>
  inputName: FieldPath<SignUpFormControl>
}) {
  const {
    field: {onChange, onBlur, name, value},
  } = useController({
    name: inputName,
    control,
    rules: {
      validate: {
        required: (value) =>
          (value !== undefined && typeof value === 'boolean') ||
          'This question is required',
      },
    },
  })

  return (
    <Select
      id={name}
      onChange={(optionValue) => {
        optionValue && onChange(optionValue.value)
      }}
      onBlur={onBlur}
      value={options.find((optionsValue) => value === optionsValue.value)}
      name={name}
      options={options}
    />
  )
}
