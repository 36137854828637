import Image from 'next/legacy/image'
import Link from 'next/link'

import {faChevronRight} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {z} from 'zod'

import {ResourceProjectionSchema} from '../resources/schemas'
import ClinicalSpecialtyDropdown from './dropdowns/ClinicalSpecialtyDropdown'
import FieldDropdown from './dropdowns/FieldDropdown'

const ResourceFieldPropsSchema = z.object({
  field: z.enum(['Eyecare', 'Optometry', 'Ophthalmology']),
  resource: ResourceProjectionSchema,
})

export default function ResourceField({
  resource,
  field,
}: z.infer<typeof ResourceFieldPropsSchema>) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Link href="/" className="navbar-brand p-0 no-hover-underline">
        <Image
          alt="Eyes On Eyecare logo"
          src="/logos/eoe-logo.png"
          width="126"
          height="35"
          objectFit="contain"
          className="border-end pe-3"
        />
      </Link>
      <FieldDropdown currentField={field} />
      {resource.clinicalSpecialty && (
        <>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="mx-2 text-primary"
          />
          <ClinicalSpecialtyDropdown
            currentClinicalSpecialty={resource.clinicalSpecialty}
            field={field}
          />
        </>
      )}
    </div>
  )
}
