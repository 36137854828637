import {baseUrl} from '../../../core/constants'
import {ResourceProjection} from '../../resources/schemas'
import {trackFacebookCustomEvent} from '../facebook'
import {setGAPageProperties, trackGoogleEvent} from '../google'
import {mixpanel} from '../mixpanel'
import {trackTwitterResourceView} from '../twitter'

interface AnalyticsFnOptions {
  onSuccess?: () => void
}
interface AnalyticsFnWithResourceOptions extends AnalyticsFnOptions {
  resource: ResourceProjection
}

export function trackViewResource({
  onSuccess,
  resource,
}: AnalyticsFnWithResourceOptions) {
  // Google Analytics
  trackGoogleEvent(
    'track_custom_dimensions',
    {
      event_category: 'custom_dimensions',
      page_location: `${baseUrl}/resources/${resource.slug}`,
      page_title: resource.title,
    },
    {
      dimension5: resource.roles && resource.roles.join(),
      dimension6: resource.containsGatedContent ? 'True' : 'False',
      dimension7: resource.mediaType,
      dimension8: resource.category,
      dimension11: resource.sponsor?.uuid ?? undefined,
      dimension16: resource.clinicalSpecialty,
      dimension17: resource.includeCampaignTracker ? 'True' : undefined,
      dimension21: resource.sponsor?.slug ?? undefined,
    },
  )
  setGAPageProperties(resource)
  // Mixpanel
  mixpanel.trackResource({
    'Contains Gated Content': resource.containsGatedContent,
    'Resource Role': resource.roles,
    'Resource Category': resource.category,
    'Resource Slug': resource.slug,
    'Resource Clinical Specialty': resource.clinicalSpecialty,
    'Resource Tags': resource.tags,
    'Resource Media Type': resource.mediaType,
    'Resource Author': resource.authors.map((author) => author.name),
  })
  // Facebook
  trackFacebookCustomEvent(
    'Viewed Resource',
    resource.sponsor
      ? {
          resource_sponsor: resource.sponsor.uuid,
          resource_sponsor_slug: resource.sponsor.slug,
        }
      : {},
  )
  // Twitter
  trackTwitterResourceView(resource)
  onSuccess?.()
}
