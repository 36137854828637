import Link from 'next/link'

import {faUserCircle} from '@fortawesome/pro-duotone-svg-icons'
import {
  faBookmark,
  faBooks,
  faEnvelope,
  faHistory,
  faSignOut,
  faThumbsUp,
} from '@fortawesome/pro-light-svg-icons'
import {faSparkles} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import useSignOut, {useUser} from '../../../core/auth'

export default function UserDropdown() {
  const {user, isLoading: isAuthenticating} = useUser()
  const {signOut} = useSignOut()
  if (!isAuthenticating && !user) {
    return (
      <Link href="/sign-up/" className="btn btn-sm btn-primary">
        <FontAwesomeIcon
          icon={faSparkles}
          className="d-none d-xl-inline-block me-2"
        />
        Subscribe
      </Link>
    )
  }

  if (!isAuthenticating && user) {
    return (
      <span className="nav-item dropdown text-uppercase">
        <a
          className="no-hover-underline"
          href="#"
          id="accountDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon
            size="2x"
            icon={faUserCircle}
            className="text-primary"
          />
        </a>
        <div className="dropdown-menu">
          <Link href="/account/liked" className="dropdown-item">
            <FontAwesomeIcon
              icon={faThumbsUp}
              style={{minWidth: '20px'}}
              className="text-primary me-2"
            />
            Liked
          </Link>
          <Link href="/account/saved" className="dropdown-item">
            <FontAwesomeIcon
              style={{minWidth: '20px'}}
              icon={faBookmark}
              className="text-primary me-2"
            />
            Saved
          </Link>
          <Link href="/account/history" className="dropdown-item">
            <FontAwesomeIcon
              icon={faHistory}
              style={{minWidth: '20px'}}
              className="text-primary me-2"
            />
            Recently Read
          </Link>
          <Link href="/account/following" className="dropdown-item">
            <FontAwesomeIcon
              icon={faBooks}
              style={{minWidth: '20px'}}
              className="text-primary me-2"
            />
            Following
          </Link>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a href="/manage-subscriptions/" className="dropdown-item">
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{minWidth: '20px'}}
              className="text-primary me-2"
            />
            Manage Subscriptions
          </a>
          <div className="dropdown-divider" />
          <button
            type="button"
            aria-label="Sign Out"
            className="dropdown-item text-uppercase fw-semibold"
            onClick={() => signOut()}
          >
            <FontAwesomeIcon
              icon={faSignOut}
              style={{minWidth: '20px'}}
              className="text-primary me-2"
            />
            Sign Out
          </button>
        </div>
      </span>
    )
  }
}
