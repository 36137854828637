import mp, {type Config} from 'mixpanel-browser'
import {z} from 'zod'

import {User} from '../../core/auth/types'
import {baseUrl} from '../../core/constants'
import {ANALYTICS_ENABLED, MIXPANEL_PROJECT_TOKEN} from './config'

const inDevelopment = process.env.NODE_ENV !== 'production'

export const MIXPANEL_PLATFORM = 'EOE'
// MIXPANEL PROFILE EVENTS
export const MIXPANEL_EVENT_RATED_CONTENT_QUALITY = 'Rated Content Quality'
export const MIXPANEL_EVENT_SUBMITTED_SIGN_IN_FORM = 'Submitted Sign In Form'
export const MIXPANEL_EVENT_STARTED_SIGN_UP_FORM = 'Started Sign Up Form'
export const MIXPANEL_EVENT_SUBMITTED_SIGN_UP_FORM = 'Submitted Sign Up Form'
export const MIXPANEL_EVENT_NPI_ADDED = 'NPI Added'
export const MIXPANEL_PROFILE_HAS_NPI = {'Has NPI': true}
// MIXPANEL USER JOURNEYS
export const MIXPANEL_JOURNEY_GATED_CONTENT_FORM = 'Gated Content Form'
export const MIXPANEL_JOURNEY_SIGN_UP_PAGE = 'Sign Up Page'
export const MIXPANEL_JOURNEY_NPI_POPUP = 'NPI Popup'

export function initializeMixpanel(config?: Partial<Config>) {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return

  mp.init(MIXPANEL_PROJECT_TOKEN as string, {
    api_host: `${baseUrl}/mp`,
    debug: inDevelopment,
    track_pageview: 'url-with-path-and-query-string',
    persistence: 'localStorage',
    ...config,
  })
}

interface MixpanelProps {
  [key: string]: string | boolean | number | undefined
}

interface MixpanelResourceProps {
  'Contains Gated Content'?: string
  'Resource Role'?: string[]
  'Resource Category'?: string
  'Resource Slug'?: string
  'Resource Clinical Specialty'?: string
  'Resource Tags'?: string[]
  'Resource Media Type'?: string
  'Resource Author'?: string[]
}

export const mixpanel = {
  identify: (id: string) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.identify(id)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel identify error: ', e)
      // else continue regardless of error
    }
  },
  get_distinct_id: () => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      return mp.get_distinct_id()
    } catch (e) {
      // continue regardless of error
    }
  },
  alias: (newId: string, existingId: string) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.alias(newId, existingId)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel alias error: ', e)
      // else continue regardless of error
    }
  },
  register: (props: MixpanelProps) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.register(props)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel register error: ', e)
      // else continue regardless of error
    }
  },
  track: (action: string, props?: MixpanelProps) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.track(action, props)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel track error: ', e)
      // else continue regardless of error
    }
  },
  trackResource: (props: MixpanelResourceProps) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.track('Viewed Resource', props)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel track resource error: ', e)
      // continue regardless of error
    }
  },
  people: {
    set: (props: MixpanelProps) => {
      if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
      try {
        mp.people.set(props)
      } catch (e) {
        if (inDevelopment) console.error('Mixpanel people set error: ', e)
        // continue regardless of error
      }
    },
    set_once: (props: MixpanelProps) => {
      if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
      try {
        mp.people.set_once(props)
      } catch (e) {
        // continue regardless of error
      }
    },
  },
  reset: () => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.reset()
    } catch (e) {
      // continue regardless of error
    }
  },
}

export function identifyMixpanelUser(user: User) {
  // If there is no profile, do not identify the user
  if (!user.profile) return

  // Get the saved mixpanel profile properties associated with the user before identification
  // The user's distinct_id should be the device_id generated by mixpanel
  // if this is the first time the user is getting identified
  const distinct_id = mixpanel.get_distinct_id()

  mixpanel.identify(user.profile.uuid)
  mixpanel.people.set({
    $email: user.email,
    $first_name: user.profile?.first_name ?? '',
    $last_name: user.profile?.last_name ?? '',
    $name: user.profile?.name ?? '',
    Role: user.profile?.role ?? '',
    'User UUID': user.profile.uuid,
  })
  mixpanel.register({
    'User UUID': user.profile.uuid,
  })
  const anonymousMixpanelProfileProps = localStorage.getItem(
    `anonymousMixpanelProfileProps:${distinct_id}`,
  )
  if (anonymousMixpanelProfileProps) {
    mixpanel.people.set_once(JSON.parse(anonymousMixpanelProfileProps))
    localStorage.removeItem(`anonymousMixpanelProfileProps:${distinct_id}`)
  }
}

const UTMsSchema = z.object({
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  utm_term: z.string().optional(),
})
export type UTMs = z.infer<typeof UTMsSchema>

export const MixpanelDataInputSchema = z.object({
  mixpanel_distinct_id: z.string().optional(),
  utms: UTMsSchema.optional(),
  Journey: z.string().optional(),
  Platform: z.string().optional(),
  Title: z.string().optional(),
})
export type MixpanelDataInput = z.infer<typeof MixpanelDataInputSchema>
