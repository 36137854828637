import Script from 'next/script'

import {ResourceProjection} from '../resources/schemas'
import {
  ANALYTICS_ENABLED,
  TWITTER_OPHTHALMOLOGY_ID,
  TWITTER_OPTOMETRY_ID,
} from './config'

// Full Event Overview on https://ads.twitter.com/conversion_events/18ce55d5udj/events_manager/o65ob/overview
export const twitterEventIdMap = {
  optometry: {
    'Eyes On Eyecare 2022 Optometrist Report': 'tw-o6655-od0nk',
  },
  ophthalmology: {
    'Ophthalmology newsletter sign up': 'tw-o65ob-o900t',
    'content downloads': 'tw-o65ob-obmtg',
    'Resource View': 'tw-o65ob-ohv1s',
  },
}

export function trackTwitterEvent(id: string) {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window.twq('event', id, {})
  } catch (e) {
    // continue regardless of error
  }
}

export function trackTwitterResourceView(resource: ResourceProjection) {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return

  function track() {
    window.twq('config', TWITTER_OPTOMETRY_ID)
    if (resource.roles?.includes('Ophthalmologist')) {
      window.twq('config', TWITTER_OPHTHALMOLOGY_ID)
      window.twq('event', twitterEventIdMap.ophthalmology['Resource View'], {
        value: resource.slug,
      })
    }
  }

  // Twitter's script is loaded asynchronously, so we need to
  // ensure it's loaded before tracking if a user goes to a resource page directly
  try {
    let count = 0
    if (window.twq) {
      track()
    } else {
      const intervalId = setInterval(() => {
        count++
        // if it takes more than 5 seconds, stop trying
        if (count > 5) clearInterval(intervalId)
        if (window.twq) {
          track()
          clearInterval(intervalId)
        }
      }, 1000) // Check every second
    }
  } catch (e) {
    // continue regardless of error
  }
}

export function TwitterInit() {
  return (
    <Script
      id={`twitter-init`}
      type="text/plain"
      data-cookieconsent="statistics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            twq('config','${TWITTER_OPTOMETRY_ID}');
            twq('config','${TWITTER_OPHTHALMOLOGY_ID}');
          `,
      }}
    />
  )
}
