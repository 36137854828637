import {FieldPath, useController} from 'react-hook-form'
import type {Control} from 'react-hook-form'

import {SignUpFormControl} from './SignUpForm'

/**
 * Render a single checkbox input.
 */
export default function CheckboxInput({
  control,
  inputName,
  label,
}: {
  control: Control<SignUpFormControl>
  inputName: FieldPath<SignUpFormControl>
  label: string
}) {
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    name: inputName,
    control,
    defaultValue: false,
  })

  return (
    <div className="d-flex align-items-center">
      <input
        type="checkbox"
        checked={!!value}
        onChange={onChange}
        className="me-2"
      />
      <label>{label}</label>
      {error && <span>{error.message}</span>}
    </div>
  )
}
