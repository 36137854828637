import {SanityImageType} from '../../core/schemas'
import type {ResourceProjection} from '../resources/schemas'
import DefaultFooter from './DefaultFooter'
import DefaultHead from './DefaultHead'
import DefaultNav from './DefaultNav'

interface DefaultLayoutProps {
  children: React.ReactChild
  customNavLogo?: SanityImageType
  field?: string
  hideFooter?: boolean
  hideHead?: boolean
  hideNav?: boolean
  pageType?: string
  resource?: ResourceProjection
}

export default function DefaultLayout({
  children,
  customNavLogo,
  field,
  hideFooter,
  hideHead,
  hideNav,
  resource,
  pageType,
}: DefaultLayoutProps) {
  return (
    <>
      {!hideHead && <DefaultHead />}
      {!hideNav && (
        <DefaultNav
          customNavLogo={customNavLogo}
          field={field}
          resource={resource}
        />
      )}
      <main>{children}</main>
      {!hideFooter && <DefaultFooter pageType={pageType} />}
    </>
  )
}
// The purpose of this function is to allow us to return a complex nested tree of layouts
// https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
// Will be getting refactored with the release of Next 13.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLayout = (page: any) => <DefaultLayout>{page}</DefaultLayout>
