import {useContext} from 'react'

import Link from 'next/link'

import {PortableText} from '@portabletext/react'

import AuthorBioStyles from '../../../styles/AuthorBio.module.scss'
import SocialLinkComponent from '../../authors/SocialLinkComponent'
import SanityImage from '../../sanity/SanityImage'
import {ResourceComponentContext} from '../ResourceComponent'

export default function AuthorBio() {
  const {resource} = useContext(ResourceComponentContext)
  return (
    <div className="card mb-lg-4" id="author-bio">
      <div className="card-body">
        <div className="d-flex flex-column align-items-center align-items-md-stretch">
          {resource.authors?.map((author, i) => (
            <div className={`${i > 0 ? 'mt-2' : ''}`} key={author._id}>
              {author.image && (
                <div className="d-flex justify-content-center align-items-center d-md-none mb-1">
                  <div className="avatar avatar-lg">
                    <SanityImage
                      src={author.image}
                      alt={author.name}
                      width={75}
                      height={75}
                      className="avatar avatar-lg"
                    />
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center text-center text-md-start mb-3">
                <div className="me-0 me-md-3">
                  <Link href={`/authors/${author.slug}/`} className="h5">
                    About {author.name}
                  </Link>
                  {author.bio && (
                    <div
                      className={`small text-gray-700 mb-2 ${AuthorBioStyles.truncate}`}
                    >
                      <PortableText value={author.bio} />
                    </div>
                  )}
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start">
                    <Link
                      href={`/authors/${author.slug}/#resources`}
                      className="btn btn-outline-primary btn-sm me-0 me-md-2"
                    >
                      More by {author.name}
                    </Link>
                    {author.socialLinks && (
                      <div className="mt-1 mt-md-0">
                        {author.socialLinks.map((link, j) => (
                          <SocialLinkComponent
                            key={`${author._id}-sociallink-${j}`}
                            link={link}
                            className={`${
                              j !== author.socialLinks.length - 1 ? 'pe-2 ' : ''
                            }mt-1 text-gray-500 fs-5`}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {author.image && (
                  <div className="d-none d-md-block avatar avatar-lg">
                    <SanityImage
                      src={author.image}
                      alt={author.name}
                      width={75}
                      height={75}
                      className="avatar avatar-lg"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
