import useSignOut, {useUser} from '../core/auth'

const SignOut = () => {
  const {signOut} = useSignOut()
  const {user} = useUser()
  const handleSignOut = () => {
    signOut()
  }
  return (
    <div
      className="container p-3 p-md-5 text-center lead"
      style={{backgroundColor: `rgb(245, 245, 245)`}}
    >
      {user?.profile?.name && `You are signed in as ${user.profile.name}. `}
      Do you need to{' '}
      <span
        tabIndex={0}
        role="button"
        className="text-decoration-underline"
        onKeyDown={handleSignOut}
        onClick={handleSignOut}
      >
        Sign out?
      </span>
    </div>
  )
}
export default SignOut
