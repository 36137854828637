import {useContext, useEffect, useMemo} from 'react'

import {useGPT} from '../../core/ads/contexts/GPTContext'
import {createAd, getAdSpecs, shouldUseCustomAds} from '../../core/ads/utils'
import {useUser} from '../../core/auth'
import {ResourceComponentContext} from '../resources/ResourceComponent'

export default function SidebarAd() {
  const {GPTHasLoaded} = useGPT()
  const {resource} = useContext(ResourceComponentContext)
  const {user, isLoading: isUserLoading} = useUser()

  // Use useMemo since adSpecs triggers re-rendering due to its usage in the useEffect
  const adSpecs = useMemo(() => {
    // If using custom ads, use the custom resource ad code, else use the provided adSpecPath prop
    const adCode =
      shouldUseCustomAds(resource) && resource?.customAd?.sidebarCode
        ? resource.customAd.sidebarCode
        : 'CovalentCareersResources_Sidebar'
    return getAdSpecs(adCode, 'sidebar', resource?._id)
  }, [resource])
  const {id: adId} = adSpecs

  // Create ad
  useEffect(() => {
    if (GPTHasLoaded && !isUserLoading) {
      return createAd(adSpecs, GPTHasLoaded, user, resource)
    }
  }, [adSpecs, GPTHasLoaded, isUserLoading, resource, user])

  return (
    <div className="d-none d-xl-flex mt-3 mb-3 justify-content-center align-items-center resource-sidebar-ad">
      <div id={adId} />
    </div>
  )
}
