import {useContext} from 'react'

import {BookmarkButton} from '../../buttons/BookmarkButton'
import {CiteButton} from '../../buttons/CiteButton'
import {LikeButton} from '../../buttons/LikeButton'
import {SocialShareButton} from '../../buttons/SocialShareButton'
import {ResourceComponentContext} from '../ResourceComponent'

export function SocialButtons() {
  const {resource, url} = useContext(ResourceComponentContext)

  if (!resource.showSocialButtons) return null

  return (
    <>
      <LikeButton />
      <BookmarkButton />
      <CiteButton
        title={resource.title}
        url={url}
        publishedDate={new Date(Date.parse(resource.publishedAt))}
        authors={resource.authors.map((author) => author.name).join(', ')}
      />
      <SocialShareButton title={resource.title} url={url} />
    </>
  )
}
