// these functions are assumed to be used if GPTHasLoaded is true
// https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
import type {ResourceProjection} from '../../components/resources/schemas'
import {type Resource} from '../../pages/resources/[slug]'
import type {User} from '../auth/types'

export interface Ad {
  path: string
  adSizes: googletag.GeneralSize
  id?: string
  sizeMap?: googletag.SizeMappingArray | null
  adType?: 'banner' | 'ctaWidget' | 'inRead' | 'sidebar' | 'stickyFooter'
}

const RESOURCE_INREAD_SPECS: Ad = {
  path: '/21611127761/CovalentCareersResources_InReadBody',
  adSizes: [
    [300, 250],
    [728, 90],
  ],
}

const RESOURCE_RECOMMENDED_SPECS: Ad = {
  path: '/21611127761/eoe_resources_recommendation',
  adSizes: ['fluid'],
}

const getAdSizes = (
  adType: 'banner' | 'ctaWidget' | 'sidebar' | 'stickyFooter',
) => {
  switch (adType) {
    // return same value for banner OR stickyFooter
    case 'banner':
    case 'stickyFooter':
      return [
        [320, 50],
        [728, 90],
        [300, 50],
      ]
    case 'ctaWidget':
      return ['fluid']
    case 'sidebar':
      return [300, 250]
  }
}

export const getSizeMap = (
  adType?: 'banner' | 'ctaWidget' | 'inRead' | 'sidebar' | 'stickyFooter',
) => {
  switch (adType) {
    case 'banner':
      return googletag
        .sizeMapping()
        .addSize([768, 0], [728, 90])
        .addSize(
          [0, 0],
          [
            [320, 50],
            [300, 50],
          ],
        )
        .build()
    case 'inRead':
      return googletag
        .sizeMapping()
        .addSize([768, 0], [728, 90])
        .addSize([0, 0], [300, 250])
        .build()
    case 'sidebar':
      return googletag
        .sizeMapping()
        .addSize([1200, 0], [300, 250])
        .addSize([0, 0], [])
        .build()
    case 'stickyFooter':
      return googletag
        .sizeMapping()
        .addSize([576, 0], [728, 90])
        .addSize(
          [0, 0],
          [
            [320, 50],
            [300, 50],
          ],
        )
        .build()
    default:
      return
  }
}

export const getAdSpecs = (
  adCode: string,
  adType: 'banner' | 'sidebar' | 'stickyFooter' | 'ctaWidget',
  id?: string,
): Ad => ({
  adSizes: getAdSizes(adType) as googletag.MultiSize,
  id: `eoe-${adType}${id ? `_${id}` : ''}`,
  path: `/21611127761/${adCode}`,
  adType,
})

export const defineSlot = ({path, adSizes, id, adType}: Ad) => {
  const slot = googletag.defineSlot(path, adSizes, id)

  if (slot) {
    const sizeMap = getSizeMap(adType)
    if (sizeMap) {
      slot.defineSizeMapping(sizeMap)
    }
    slot.setCollapseEmptyDiv(true)
    return slot.addService(googletag.pubads())
  }
}

export const createAd = (
  adSpecs: Ad,
  GPTHasLoaded: boolean,
  user: User | null | undefined,
  resource?: Resource,
  onAdRendered?: () => void,
  refresh = true,
) => {
  let adSlot: googletag.Slot | undefined
  let refreshAdCleanup: () => void

  function onSlotRenderEnded(event: googletag.events.SlotRenderEndedEvent) {
    if (event.slot.getSlotElementId() === adSpecs.id && !event.isEmpty) {
      if (onAdRendered) onAdRendered()
    }
  }

  if (adSpecs && GPTHasLoaded) {
    // create ad
    try {
      googletag.cmd.push(() => {
        adSlot = defineSlot(adSpecs)

        if (adSlot) {
          setUserTargeting(adSlot, user)
          if (resource) setResourceAdTargeting(adSlot, resource)
          if (refresh) {
            refreshAdCleanup = refreshAfterViewable(adSlot)
          }
        }

        googletag.pubads().enableLazyLoad({
          fetchMarginPercent: 100,
          renderMarginPercent: 50,
          mobileScaling: 3.0,
        })

        googletag.enableServices()
      })
    } catch (e) {
      // continue regardless of error
    }

    // display ad
    try {
      googletag.cmd.push(() => {
        adSpecs.id && googletag.display(adSpecs.id)
        if (onAdRendered) {
          googletag
            .pubads()
            .addEventListener('slotRenderEnded', onSlotRenderEnded)
        }
      })
    } catch (e) {
      // continue regardless of error
    }
  }

  return function cleanup() {
    if (adSlot) {
      try {
        googletag.cmd.push(function () {
          if (refreshAdCleanup) {
            refreshAdCleanup()
          }
          if (onAdRendered) {
            googletag
              .pubads()
              .removeEventListener('slotRenderEnded', onSlotRenderEnded)
          }
          if (googletag.destroySlots && adSlot) {
            googletag.destroySlots([adSlot])
          }
        })
      } catch (e) {
        // continue regardless of error
      }
    }
  }
}

export const createAdSlot = (
  adName: 'resourceInRead' | 'resourceRecommended' | 'ctaWidget',
  adCode?: string,
) => {
  switch (adName) {
    case 'resourceInRead':
      return defineSlot({
        path: adCode ? `/21611127761/${adCode}` : RESOURCE_INREAD_SPECS.path,
        adSizes: RESOURCE_INREAD_SPECS.adSizes,
        sizeMap: googletag
          .sizeMapping()
          .addSize([768, 0], [728, 90])
          .addSize([0, 0], [300, 250])
          .build(),
        adType: 'inRead',
      })
    case 'resourceRecommended':
      return defineSlot({
        path: RESOURCE_RECOMMENDED_SPECS.path,
        adSizes: RESOURCE_RECOMMENDED_SPECS.adSizes,
      })
  }
}

export function displayGPTAd(adID: string | Element | googletag.Slot) {
  // https://developers.google.com/publisher-tag/reference#window.googletag.display
  // Instructs slot services to render the slot. Each ad slot should only be displayed once per page.
  // All slots must be defined and have a service associated with them before being displayed.
  // The display call must not happen until the element is present in the DOM.
  try {
    googletag.cmd.push(() => {
      googletag.display(adID)
    })
  } catch (e) {
    // continue regardless of error
  }
}

export function insertRecommendedResourceAd(
  recs: JQuery,
  adClass: string,
  position: number,
  resource: ResourceProjection,
  user: User | null | undefined,
  slots: googletag.Slot[],
) {
  googletag.cmd.push(() => {
    const slot = createAdSlot('resourceRecommended')
    if (slot !== undefined) {
      setUserTargeting(slot, user)
      setResourceAdTargeting(slot, resource)

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 100,
        renderMarginPercent: 50,
        mobileScaling: 3.0,
      })

      const id = slot.getSlotElementId()
      const div = document.createElement('div')
      div.id = id
      div.className = adClass

      recs[position].before(div)
      window.googletag.display(slot)
      slots.push(slot)
    }
  })
}

export function insertGPTInReadAd(
  element: HTMLElement | undefined,
  user: User | null | undefined,
  resource: ResourceProjection,
  slots: googletag.Slot[],
  refreshAdCleanups: Array<() => void>,
  adCode?: string,
) {
  googletag.cmd.push(() => {
    const slot = createAdSlot('resourceInRead', adCode)

    if (slot && element) {
      setUserTargeting(slot, user)
      if (resource) {
        setResourceAdTargeting(slot, resource)
      }
      const div = document.createElement('div')
      div.className =
        'd-flex flex-column align-items-center resource_dynamic_inread'
      const adId = slot.getSlotElementId()
      div.id = adId

      element.before(div)
      googletag.display(slot)

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        if (event.slot.getSlotElementId() === adId) {
          if (!event.isEmpty) {
            div.className += ' my-5'
          }
        }
      })

      const refreshAdCleanup = refreshAfterViewable(slot)
      slots.push(slot)
      refreshAdCleanups.push(refreshAdCleanup)
    }
  })
}

function setUserTargeting(slot: googletag.Slot, user: User | null | undefined) {
  if (user) {
    slot.setTargeting('auth', 'true')
    if (!!user.profile?.pldls && user.profile.pldls.length > 0) {
      slot.setTargeting('ta', user.profile.pldls)
    }
  } else {
    slot.setTargeting('auth', 'false')
  }
}

function setResourceAdTargeting(
  slot: googletag.Slot,
  resource: ResourceProjection,
) {
  if (resource.clinicalSpecialty) {
    // "clinical_specialty" isn't connected to the google analytics' variable, it uses the google ad manager's naming:
    // https://admanager.google.com/21611127761#inventory/custom_targeting/detail/custom_key_id=12311363
    slot.setTargeting('clinical_specialty', resource.clinicalSpecialty)
  }

  if (resource.category) {
    slot.setTargeting('Topic', resource.category)
  }

  if (resource.roles) {
    slot.setTargeting('Role', resource.roles)
  }

  if (resource.tags) {
    slot.setTargeting('Tag', resource.tags)
  }

  if (resource.sponsor) {
    if (resource.sponsor.uuid) {
      slot.setTargeting('Company', resource.sponsor.uuid)
    }
    slot.setTargeting('Company_Slug', resource.sponsor.slug)
  }
}

export function refreshAfterViewable(targetSlot: googletag.Slot, mins = 2) {
  let isFirstOccurrence = true
  let intervalId: number | undefined

  function refreshAd(event: googletag.events.ImpressionViewableEvent) {
    const slot = event.slot

    if (slot === targetSlot && isFirstOccurrence) {
      intervalId = window.setInterval(
        () => {
          if (googletag.cmd) {
            googletag.cmd.push(() => {
              googletag.pubads().refresh([targetSlot])
            })
          }
        },
        1000 * 60 * mins,
      )
      isFirstOccurrence = false
    }
  }
  googletag.pubads().addEventListener('impressionViewable', refreshAd)

  return function cleanup() {
    googletag.pubads().removeEventListener('impressionViewable', refreshAd)
    if (intervalId !== undefined) {
      clearInterval(intervalId)
    }
  }
}

// Only use custom ads if they are enabled, and if the end date is in the future if it exists
export function shouldUseCustomAds(resource?: ResourceProjection) {
  if (resource?.useCustomAds) {
    if (resource.customAdsEndDateTime) {
      return new Date(resource.customAdsEndDateTime) > new Date()
    } else {
      return true
    }
  }
  return false
}
