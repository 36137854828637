/* eslint-disable import/prefer-default-export */
import DefaultTemplate from './template/DefaultTemplate'
import FullWidthTemplate from './template/FullWidthTemplate'

export function getTemplate(templateType) {
  return {
    default: DefaultTemplate,
    'full-width': FullWidthTemplate,
    animated: FullWidthTemplate,
  }[templateType ?? 'default']
}

export function getField(resource) {
  const {roles} = resource
  if (!roles || !roles.length) {
    return 'Eyecare'
  }
  if (roles.includes('Ophthalmologist')) {
    return 'Ophthalmology'
  }
  return 'Optometry'
}

export function getResourceSlug(slug) {
  return `/resources/${slug}/`
}

export function getSlugFromUrl(url) {
  return url.split('/resources/')[1].split('/')[0]
}
