import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import {useUser} from '../../core/auth'
import {baseUrl} from '../../core/constants'
import {
  ResourceTableOfContents,
  parseLeadFormFromResource,
  parseTableOfContents,
} from '../../core/hooks/useResource'
import {Resource, ResourcePageContext} from '../../pages/resources/[slug]'
import {trpc} from '../../utils/trpc'
import ResourceAnalytics from '../analytics/resource/ResourceAnalytics'
import {getTemplate} from './utils'

interface ResourceComponentProps {
  resource: Resource
  isVisible: boolean
  preview: boolean
  resourceIndex: number
}
export const ResourceComponent = ({
  resource,
  isVisible,
  preview,
  resourceIndex,
}: ResourceComponentProps) => {
  const {resource: primaryResource} = useContext(ResourcePageContext)
  const Template = getTemplate(primaryResource.templateType)
  const ref = useRef(null)
  return (
    <ResourceComponentProvider
      resource={resource}
      isVisible={isVisible}
      preview={preview}
      resourceIndex={resourceIndex}
    >
      <div
        ref={ref}
        className={resourceIndex > 0 ? 'mt-lg-5 border-top pt-lg-5' : ''}
      >
        <ResourceAnalytics />
        <Template resource={resource} />
      </div>
    </ResourceComponentProvider>
  )
}

interface ResourceComponentContext {
  resource: Resource
  preview: boolean
  isVisible: boolean
  resourceIndex: number
  hasLiked: boolean
  likeResource(): void
  unlikeResource(): void
  hasBookmarked: boolean
  bookmarkResource(): void
  unbookmarkResource(): void
  hasViewed: boolean
  viewResource(): void
  hits: number
  hitResource(): void
  tableOfContents: ResourceTableOfContents[]
  leadForm: unknown
  url: string
}
export const ResourceComponentContext = createContext<ResourceComponentContext>(
  {} as ResourceComponentContext,
)
/**
 * Use this provider to wrap a resource component in common functionality.
 * Use `disableAnalytics` to prevent POSTing hits and views on mount.
 */
export const ResourceComponentProvider = ({
  children,
  resource,
  preview,
  isVisible,
  resourceIndex,
  disableAnalytics = false,
}: {
  children?: ReactNode
  resource: Resource
  preview?: boolean
  isVisible?: boolean
  resourceIndex?: number
  disableAnalytics?: boolean
}) => {
  const [hasLiked, setHasLiked] = useState(false)
  const [hasBookmarked, setHasBookmarked] = useState(false)
  const [hasViewed, setHasViewed] = useState(false)
  const [hits, setHits] = useState(0)
  const {user} = useUser()
  if (preview === undefined) {
    preview = false
  }
  if (isVisible === undefined) {
    isVisible = false
  }
  if (resourceIndex === undefined) {
    resourceIndex = 0
  }

  /**
   * On mount, get the resource's user-based analytics
   */
  trpc.analytics.protectedGetAnalytics.useQuery(
    {sanityId: resource._id},
    {
      refetchOnWindowFocus: false,
      enabled: !!user,
      onSettled: (data) => {
        if (!data) return
        setHasLiked(data.liked)
        setHasBookmarked(data.bookmarked)
        setHasViewed(data.viewed)
      },
    },
  )

  /**
   * Consumers can use `bookmarkResource()` to bookmark the resource
   */
  const {mutate: logBookmark} =
    trpc.analytics.protectedLogBookmark.useMutation()
  const bookmarkResource = useCallback(() => {
    if (!user) return
    setHasBookmarked(true)
    logBookmark({sanityId: resource._id})
  }, [user, logBookmark, setHasBookmarked, resource._id])

  /**
   * Consumers can use `unbookmarkResource()` to unbookmark the resource
   */
  const {mutate: logUnbookmark} =
    trpc.analytics.protectedUnlogBookmark.useMutation()
  const unbookmarkResource = useCallback(() => {
    if (!user) return
    setHasBookmarked(false)
    logUnbookmark({sanityId: resource._id})
  }, [user, logUnbookmark, setHasBookmarked, resource._id])

  /**
   * Consumers can use `likeResource()` to like a resource
   */
  const {mutate: logLike} = trpc.analytics.protectedLogLike.useMutation()
  const likeResource = useCallback(() => {
    if (!user) return
    setHasLiked(true)
    logLike({sanityId: resource._id})
  }, [user, setHasLiked, logLike, resource._id])

  /**
   * Consumers can use `unlikeResource()` to unlike a resource
   */
  const {mutate: unlogLike} = trpc.analytics.protectedUnlogLike.useMutation()
  const unlikeResource = useCallback(() => {
    if (!user) return
    setHasLiked(false)
    unlogLike({sanityId: resource._id})
  }, [user, unlogLike, setHasLiked, resource._id])

  /**
   * Consumers can use `viewResource()` to log a resource as viewed by the user
   */
  const {mutate: logView} = trpc.analytics.protectedLogView.useMutation()
  const viewResource = useCallback(() => {
    if (!user || disableAnalytics) return
    setHasViewed(true)
    // Send resource metadata so we can use it downstream in the golden realms
    // without having to fetch it again there.
    logView({
      sanityId: resource._id,
      title: resource.title,
      category: resource.category,
      clinicalSpecialty: resource.npiqClinicalSpecialty,
      tags: resource.tags,
      sponsor: resource.sponsor,
    })
  }, [disableAnalytics, logView, resource, user])

  /**
   * Log view on mount
   */
  useEffect(() => {
    viewResource()
  }, [viewResource])

  /**
   * Consumers can use `hitResource()` to log a hit count for a resource manually.
   * Default behavior is for this to be called on mount.
   */
  const {mutate: logHit} = trpc.analytics.logHitCount.useMutation({
    onSettled: (data) => {
      setHits(data || 0)
    },
  })
  const hitResource = useCallback(() => {
    if (disableAnalytics) return
    logHit({sanityId: resource._id, sanityType: 'resourcePage'})
  }, [disableAnalytics, logHit, resource._id])
  /**
   * Log hit on mount
   */
  useEffect(() => {
    hitResource()
  }, [hitResource])

  const tableOfContents = parseTableOfContents(resource)
  const leadForm = parseLeadFormFromResource(resource)
  const url = `${baseUrl}/resources/${resource.slug}/`
  return (
    <ResourceComponentContext.Provider
      value={{
        resource,
        preview,
        isVisible,
        resourceIndex,
        hasLiked,
        likeResource,
        unlikeResource,
        hasBookmarked,
        bookmarkResource,
        unbookmarkResource,
        hasViewed,
        viewResource,
        hits,
        hitResource,
        tableOfContents,
        leadForm,
        url,
      }}
    >
      {children}
    </ResourceComponentContext.Provider>
  )
}
