import {useRef, useState} from 'react'
import {FlashcardArray} from 'react-quizlet-flashcard'

import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import useHasMounted from '../../../core/hooks/useHasMounted'
import {SanityBlock} from '../../../core/schemas'
import {featuredTextSerializers} from '../../../core/serializers'
import SanityImage from '../../sanity/SanityImage'
import VideoEmbedBlock from './VideoEmbedBlock'

const FlashcardsSchema = z.object({
  flashcards: z.array(
    z.object({
      _key: z.string(),
      question: z.array(SanityBlock),
      answer: z.array(SanityBlock),
    }),
  ),
})

export default function Flashcards({
  flashcards,
}: z.infer<typeof FlashcardsSchema>) {
  // we need to keep track of the flip status for all cards in case there's a video embed
  // since we need to control the z-index based on the flip status of a card
  // due to videos being clicked even when they're not visible
  const [flipStatus, setFlipStatus] = useState(
    new Array(flashcards.length).fill(false),
  )
  const [currentCard, setCurrentCard] = useState(1)
  const hasMounted = useHasMounted()

  const controlRef = useRef<{
    nextCard: () => void
    prevCard: () => void
    resetArray: () => void
  }>({
    nextCard: () => {},
    prevCard: () => {},
    resetArray: () => {},
  })
  const currentCardFlipRef = useRef<() => void>(() => {})

  const onCardFlip = (_: number, index: number, flipped: boolean) => {
    // Update flip status only for the card that is flipped
    setFlipStatus((prevStatus) => {
      const newStatus = [...prevStatus]
      newStatus[index] = flipped
      return newStatus
    })
  }

  const cards = flashcards.map((flashcard, i) => {
    // Ensure whichever side of the card is flipped to the front has a zIndex of 1
    const zIndex = flipStatus[i] ? -1 : 1

    return {
      id: i,
      frontHTML: (
        <div
          className="text-white d-flex flex-column justify-content-center align-items-center p-3 p-lg-5 h-100 font-nunito heading-inherit-styles"
          onClick={() => currentCardFlipRef.current()}
          role="button"
        >
          <PortableText
            value={flashcard.question}
            components={{
              ...featuredTextSerializers,
              types: {
                videoEmbed: ({value}) => {
                  return (
                    <div className="w-100 h-100" style={{zIndex}}>
                      <VideoEmbedBlock height="100%" url={value.url} />
                    </div>
                  )
                },
                linkableImage: ({value}) => {
                  return (
                    <SanityImage
                      src={value.image.asset}
                      width={value.dimensions.width}
                      height={value.dimensions.height}
                      alt={''}
                      objectFit="contain"
                    />
                  )
                },
              },
            }}
          />
        </div>
      ),
      backHTML: (
        <div
          className="d-flex flex-column justify-content-center align-items-center p-3 p-lg-5 h-100 position-relative"
          onClick={() => currentCardFlipRef.current()}
          role="button"
        >
          <PortableText
            value={flashcard.answer}
            components={{
              ...featuredTextSerializers,
              types: {
                videoEmbed: ({value}) => {
                  return (
                    <div className="w-100 h-100" style={{zIndex: -zIndex}}>
                      <VideoEmbedBlock height="100%" url={value.url} />
                    </div>
                  )
                },
                linkableImage: ({value}) => {
                  return (
                    <SanityImage
                      src={value.image.asset}
                      width={value.dimensions.width}
                      height={value.dimensions.height}
                      alt={''}
                      objectFit="contain"
                    />
                  )
                },
              },
            }}
          />
        </div>
      ),
    }
  })

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center my-5"
      style={{gap: 12}}
    >
      {hasMounted && (
        <>
          <div className="wobble-on-hover d-flex justify-content-center w-100">
            <FlashcardArray
              cards={cards}
              controls={false}
              showCount={false}
              backCardStyle={{border: '1px solid gray'}}
              frontCardStyle={{backgroundColor: '#3a92e0'}}
              onCardFlip={onCardFlip}
              forwardRef={controlRef}
              currentCardFlipRef={currentCardFlipRef}
              onCardChange={(_, index) => {
                setCurrentCard(index)
              }}
            />
          </div>
          {cards.length > 1 && (
            <div className="row align-items-center">
              <FontAwesomeIcon
                role="button"
                icon={faChevronCircleLeft}
                className={`text-primary col ${currentCard === 1 && 'invisible'}`}
                size="2xl"
                aria-hidden="true"
                onClick={() => controlRef.current.prevCard()}
              />
              <span className="col">
                {currentCard} / {cards.length}
              </span>
              <FontAwesomeIcon
                role="button"
                icon={faChevronCircleRight}
                className={`text-primary col ${
                  currentCard === cards.length && 'invisible'
                }`}
                size="2xl"
                aria-hidden="true"
                onClick={() => controlRef.current.nextCard()}
              />
            </div>
          )}
          <button
            className="btn btn-primary"
            onClick={() => currentCardFlipRef.current()}
          >
            Flip
          </button>
        </>
      )}
    </div>
  )
}
