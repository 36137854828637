import {KeyboardEvent, MouseEvent, useState} from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import Image from 'next/legacy/image'
import {useRouter} from 'next/router'

import {z} from 'zod'

import useSignOut, {useUser} from '../../core/auth'
import SearchBar from '../forms/SearchBar'
import ResubscribeAlert from './ResubscribeAlert'

const DefaultMobileNavPropsSchema = z.object({
  field: z.string(),
})

export default function DefaultMobileNav({
  field,
}: z.infer<typeof DefaultMobileNavPropsSchema>) {
  const {user} = useUser()
  const {signOut} = useSignOut()
  const [expanded, setExpanded] = useState(false)

  const router = useRouter()

  const onClickRedirect = (
    e:
      | MouseEvent<HTMLImageElement | HTMLSpanElement>
      | KeyboardEvent<HTMLSpanElement>,
    href: string,
  ) => {
    if (e.type === 'keydown' && 'key' in e && e.key !== 'enter') {
      return
    }
    setExpanded(false)
    router.push(href)
  }

  return (
    <div className="d-lg-none">
      <Navbar expanded={expanded} expand="lg" className="shadow">
        <Navbar.Brand className="navbar-brand d-flex align-items-center">
          <Image
            onClick={(e) => {
              onClickRedirect(e, '/')
            }}
            alt="Eyes On Eyecare logo"
            src="/logos/eoe-logo.png"
            width="100"
            height="35"
            objectFit="contain"
          />
          {field && (
            <span className="fw-semibold fs-6 text-dark text-uppercase ms-2">
              {field}
            </span>
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="mobileNav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="mobileNav">
          <Nav className="me-auto">
            <div className="my-3 mx-1 py-3 border-top">
              <div className="mb-2">
                <SearchBar onSubmit={() => setExpanded(false)} />
              </div>
              <div className="d-flex justify-content-start">
                {user ? (
                  <span
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => signOut()}
                    onClick={() => signOut()}
                    className="btn btn-sm btn-link m-0 me-2"
                  >
                    Log Out
                  </span>
                ) : (
                  <>
                    <span
                      tabIndex={0}
                      role="button"
                      onKeyDown={(e) => {
                        onClickRedirect(e, '/sign-up/')
                      }}
                      onClick={(e) => onClickRedirect(e, '/sign-up/')}
                      className="btn btn-sm btn-bold m-0 me-2"
                    >
                      Create Account
                    </span>
                    <span
                      tabIndex={0}
                      role="button"
                      onKeyDown={(e) => onClickRedirect(e, '/sign-in/')}
                      onClick={(e) => onClickRedirect(e, '/sign-in/')}
                      className="btn btn-sm btn-bold m-0 me-2"
                    >
                      Sign in
                    </span>
                  </>
                )}
              </div>
              <div className="d-flex flex-column mt-3 border-top pt-3">
                <span className="text-strong text-dark mb-1 serif">Field</span>
                <span>
                  <span
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => onClickRedirect(e, '/optometry/')}
                    onClick={(e) => onClickRedirect(e, '/optometry/')}
                  >
                    Optometry
                  </span>
                </span>
                <span>
                  <span
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => onClickRedirect(e, '/ophthalmology/')}
                    onClick={(e) => onClickRedirect(e, '/ophthalmology/')}
                  >
                    Ophthalmology
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">
                  Clinical Articles
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Ocular+Surface">
                    Ocular Surface
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Contact+Lens">
                    Contact Lenses
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Glaucoma">Glaucoma</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Retina">Retina</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Pediatrics">
                    Pediatrics
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/">
                    <span className="text-muted text-small">
                      SEE MORE &raquo;
                    </span>
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">Jobs</span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/?role=Optometrist">
                    Optometrist Jobs
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/?role=Ophthalmologist">
                    Ophthalmologist Jobs
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/?role=Optician">
                    Optician &amp; Technician Jobs
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/eyecare-hiring-products/">
                    Hiring Services for Employers
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/">
                    <span className="text-muted text-small">
                      SEE ALL JOBS &raquo;
                    </span>
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">
                  Resources
                </span>
                {/* disabled due to algolia router compat issues */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/resources/?media_type=Courses&utm_campaign=mobile_menu">
                  Courses
                </a>
                {/* disabled due to algolia router compat issues */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/resources/?media_type=Reports&utm_campaign=mobile_menu">
                  Reports
                </a>
                {/* disabled due to algolia router compat issues */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/resources/?media_type=Cheat%20Sheets&utm_campaign=mobile_menu">
                  Cheat Sheets
                </a>
                {/* disabled due to algolia router compat issues */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/resources/?q=Ultimate%20Guide&utm_campaign=mobile_menu">
                  Ultimate Guides
                </a>
                {/* disabled due to algolia router compat issues */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/resources/?media_type=Calculator&utm_campaign=mobile_menu">
                  Calculators &amp; Tools
                </a>
                <span
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e) =>
                    onClickRedirect(e, '/interventional-mindset/')
                  }
                  onClick={(e) =>
                    onClickRedirect(e, '/interventional-mindset/')
                  }
                >
                  Interventional Mindset
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?utm_campaign=mobile_menu">
                    <span className="text-muted text-small">
                      SEE ALL RESOURCES &raquo;
                    </span>
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">
                  Career Articles
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Finance">Personal Finance</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Job+Search">Job Search</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Practice+Management">
                    Practice Management
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Pre-Optometry">Pre-Optometry</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Grad+School">Grad School</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/">
                    <span className="text-muted text-small">
                      SEE MORE &raquo;
                    </span>
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">Events</span>
                <span>
                  <a href="https://events.eyesoneyecare.com/">Event Schedule</a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">
                  Courses
                </span>
                <span>
                  <a href="https://courses.eyesoneyecare.com/ce-cme/">CE/CME</a>
                </span>
                <span>
                  <a href="https://courses.eyesoneyecare.com/non-ce-cme/">
                    Non-CE/CME
                  </a>
                </span>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ResubscribeAlert />
    </div>
  )
}
