import React, {useEffect} from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: fancyapp should have ts in future v5 update
import {Fancybox as NativeFancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

interface FancyboxProps {
  options: {infinite: boolean}
  children: JSX.Element
}

const Fancybox = ({options, children}: FancyboxProps) => {
  const delegate = '[data-fancybox]'

  useEffect(() => {
    const opts = options || {}

    NativeFancybox.bind(delegate, opts)

    return () => {
      NativeFancybox.destroy()
    }
  }, [options])

  return <>{children}</>
}

interface FancyboxWrapperProps {
  id: string
  src: string
  caption?: string
  children: JSX.Element
}

/**
 * Use this FancyboxWrapper to wrap around any element (button, div, etc) and grant it fancybox powers.
 * Use an image or video url for src, and when clicked, it will open in a lightbox view.
 * Use matching IDs on the same page to create a gallery, otherwise use distinct IDs for single views.
 */
const FancyboxWrapper = ({
  id,
  src,
  caption,
  children,
}: FancyboxWrapperProps) => {
  return (
    <Fancybox options={{infinite: false}}>
      <div
        data-fancybox={id}
        data-src={src}
        data-caption={caption}
        style={{cursor: 'pointer'}}
      >
        {children}
      </div>
    </Fancybox>
  )
}

export default FancyboxWrapper
