import {useState} from 'react'
import type {ChangeEvent, FormEvent} from 'react'

import {faSearch} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function SearchBar({
  buttonClass = 'btn-primary',
  containerClass = '',
  displayIcon = true,
  inputClass = '',
  onSubmit,
  placeholder = 'Search by keyword',
}: {
  buttonClass?: string
  containerClass?: string
  displayIcon?: boolean
  inputClass?: string
  onSubmit?: () => void
  placeholder?: string
}) {
  const [route, setRoute] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoute(e.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
    window.location.href = `/resources/?q=${route}`
  }

  return (
    <form className={`${containerClass}`} onSubmit={handleSubmit}>
      <div className="input-group">
        <input
          onChange={handleChange}
          value={route}
          className={`form-control form-control-sm ${inputClass}`}
          type="text"
          placeholder={placeholder}
        />
        <button
          type="submit"
          className={`btn btn-sm d-flex align-items-center ${buttonClass}`}
        >
          {displayIcon && (
            <FontAwesomeIcon icon={faSearch} className="text-white me-1" />
          )}
          <span>Search</span>
        </button>
      </div>
    </form>
  )
}
