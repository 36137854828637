import {
  faCalendarCheck,
  faEye,
  faHandshake,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

function RichTextBlock({icon, color, iconColor, text}) {
  let faIcon
  switch (icon) {
    case 'handshake':
      faIcon = faHandshake
      break
    case 'eye':
      faIcon = faEye
      break
    case 'calendar-check':
      faIcon = faCalendarCheck
      break
    default:
      faIcon = null
  }
  return (
    <div className={`alert alert-${color} pb-0 d-flex align-items-center`}>
      {icon && (
        <div style={{marginRight: '1rem'}} className="mb-3">
          <FontAwesomeIcon
            icon={faIcon}
            className={iconColor && `text-${iconColor}`}
          />
        </div>
      )}
      <div>
        <PortableText value={text} />
      </div>
    </div>
  )
}

export default RichTextBlock
