import {useEffect} from 'react'

import type {AppProps} from 'next/app'
import {useRouter} from 'next/router'

import {config} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Analytics} from '@vercel/analytics/react'
import 'bootstrap/dist/css/bootstrap.min.css'

import AnalyticsInits from '../components/analytics/AnalyticsInits'
import {
  trackPageReferrer,
  trackPageView,
} from '../components/analytics/tracking/trackPageView'
import {saveUTMsToCookie} from '../components/analytics/utils'
import DefaultLayout from '../components/layouts/DefaultLayout'
import GPTScripts from '../core/ads/GPTScripts'
import {AdTrackingProvider} from '../core/ads/contexts/AdTrackingContext'
import {GPTProvider} from '../core/ads/contexts/GPTContext'
import {AuthProvider} from '../core/auth'
import '../styles/custom.scss'
import '../styles/globals.scss'
import '../styles/hubspot.scss'
import {trpc} from '../utils/trpc'
import {Resource} from './resources/[slug]'

// Extends the window object to include jQuery and $
declare global {
  interface Window {
    $: JQueryStatic
    jQuery: JQueryStatic
    mixpanel?: Mixpanel
  }
}

const queryClient = new QueryClient()

// https://fontawesome.com/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false

function App({Component, pageProps}: AppProps) {
  useEffect(() => {
    // @ts-expect-error Investigate typing bootstrap https://app.clickup.com/t/3u48k8d
    import('bootstrap/dist/js/bootstrap')
  }, [])

  // Track route changes and send page views to Google Tag Manager
  const router = useRouter()
  useEffect(() => {
    router.events.on('beforeHistoryChange', trackPageReferrer)
    router.events.on('routeChangeComplete', trackPageView)
    return () => {
      router.events.off('routeChangeComplete', trackPageView)
      router.events.off('beforeHistoryChange', trackPageReferrer)
    }
  }, [router.events])

  // Ensure that utms persist throughout a session and are
  // updated on route changes in case the user navigates to a page with other utms
  useEffect(() => {
    saveUTMsToCookie()
  }, [router])

  /**
   * Some pages mount cc3 react apps on top of the DOM, or load hubspot lead forms.
   * These pages need to load the cc3 styles and jquery.
   */
  useEffect(() => {
    const reactAppOverrides = [
      'time-to-hire-calculator',
      'optometry-student-debt-calculator',
      'best-ophthalmology-residency-calculator',
      'real-cost-of-hiring-an-optometrist',
      'the-best-optometry-schools',
      'optometrist-salary-calculator',
    ]
    const appProps = pageProps as {
      primaryResource?: Resource
    }
    if (
      reactAppOverrides.includes(router.query?.slug as string) ||
      appProps?.primaryResource?.leadForm
    ) {
      //@ts-expect-error scss import
      import('../styles/cc3.scss')
      window.$ = window.jQuery = require('jquery')
    }
  }, [pageProps, router.query])

  // Use the layout defined at the page level, if available;
  // otherwise use default layout
  // Will be getting refactored with the release of Next 13.
  const getLayout =
    // @ts-expect-error getLayout will be getting refactored with the release of Next 13.
    Component.getLayout ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((page: any) => <DefaultLayout>{page}</DefaultLayout>)

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthProvider>
          <AnalyticsInits />
          <GPTProvider>
            <AdTrackingProvider>
              <GPTScripts />
              {getLayout(<Component {...pageProps} />, pageProps)}
            </AdTrackingProvider>
          </GPTProvider>
        </AuthProvider>
      </QueryClientProvider>
      <Analytics />
    </>
  )
}

export default trpc.withTRPC(App)
