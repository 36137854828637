import dynamic from 'next/dynamic'

import {faChevronDown} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {v4 as uuidv4} from 'uuid'

const Collapsible = dynamic(() => import('react-collapsible'), {ssr: false})

export default function TableOfContents({sections}) {
  return (
    <div className="rounded border shadow-sm mb-5">
      <div
        style={{background: '#eef9fc'}}
        className="ps-3 py-3 blocked border-bottom"
      >
        TABLE OF CONTENTS
      </div>

      {sections.map((section) => {
        const preview = (
          <span key={uuidv4()} className="text-primary text-nowrap">
            {section.subsections.length} Section
            {section.subsections.length > 1 && 's'}
            <FontAwesomeIcon icon={faChevronDown} className="ms-3" />
          </span>
        )
        return (
          <div key={uuidv4()} className="p-3 border-bottom">
            <Collapsible
              trigger={[section.title, preview]}
              transitionTime={300}
              easing="ease-in"
              triggerClassName="d-flex justify-content-between"
              triggerOpenedClassName="d-flex justify-content-between"
            >
              <div>
                {section.subsections.map((s) => {
                  return (
                    <div key={s.id} className="pt-2 ps-2">
                      <a href={`#${s.id}`} style={{color: '#495057'}}>
                        {s.title}
                      </a>
                    </div>
                  )
                })}
              </div>
            </Collapsible>
          </div>
        )
      })}
    </div>
  )
}
