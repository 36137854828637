import {faCheck} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {v4 as uuidv4} from 'uuid'

export default function LearningObjectives({title, objectives}) {
  return (
    <div className="border rounded shadow-sm mb-5">
      <div
        style={{background: '#eef9fc'}}
        className="ps-3 py-3 blocked border-bottom"
      >
        {title || "WHAT YOU'LL LEARN"}
      </div>
      <div className="my-2 px-2">
        {objectives.map((objective) => {
          return (
            <div key={uuidv4()} className="p-2">
              <FontAwesomeIcon icon={faCheck} className="text-success" />{' '}
              {objective}
            </div>
          )
        })}
      </div>
    </div>
  )
}
