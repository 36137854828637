import {useEffect} from 'react'

import * as Sentry from '@sentry/nextjs'

import {type _AdDivInfo} from '.'
import {trpc} from '../../../../utils/trpc'
import {useUser} from '../../../auth'
import {useGPT} from '../GPTContext'

/**
 * Internal hook for AdTrackingContext that sets up ad impressions and click tracking for authenticated users viewing a resource.
 */
export function _useAdTracking(adDivs: _AdDivInfo) {
  const {mutate: track} = trpc.analytics.trackGenericUserEvent.useMutation()
  const {GPTHasLoaded} = useGPT()
  const {isLoading: isUserLoading} = useUser()

  // this hook sends a "Impression" event when the ad unit has been loaded.
  useEffect(() => {
    if (!GPTHasLoaded || isUserLoading) return

    async function onSlotRenderEnded(
      event: googletag.events.SlotRenderEndedEvent,
    ) {
      if (event.isEmpty) return
      await track(
        {
          event: 'Impression',
          properties: {
            adUnitPath: event.slot.getAdUnitPath(),
            advertiserId: event.advertiserId
              ? event.advertiserId.toString()
              : null,
            campaignId: event.campaignId ? event.campaignId.toString() : null,
            creativeId: event.creativeId ? event.creativeId.toString() : null,
            lineItemId: event.lineItemId ? event.lineItemId.toString() : null,
          },
        },
        {
          onError: (e) => {
            if (process.env.NODE_ENV === 'development') {
              console.error(e)
            } else {
              Sentry.captureException(e)
            }
          },
        },
      )
    }

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded)
    })

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded)
      })
    }
  }, [GPTHasLoaded, isUserLoading, track])

  // iframe event listener
  useEffect(() => {
    if (!GPTHasLoaded || isUserLoading) return

    async function iframeFunction() {
      setTimeout(async () => {
        if (
          document.activeElement &&
          document.activeElement.tagName === 'IFRAME'
        ) {
          // prevent the iframe from stealing focus and thus breaking tracking if user clicks on another iframe
          setTimeout(() => {
            window.focus()
          }, 1)
          if (
            // only track if the iframe is a google ad
            document.activeElement.id.startsWith('google_ads_iframe') &&
            // we can only track and report on clicks if we are able to determine the ad div id;
            // this is only possible if the iframe's container element's parent is the ad div
            document.activeElement.parentElement?.parentElement?.id
          ) {
            const slot =
              adDivs[document.activeElement.parentElement?.parentElement?.id]
            if (slot)
              await track(
                {
                  event: 'Click',
                  properties: {
                    adUnitPath: slot.adUnitPath,
                    advertiserId: slot.advertiserId,
                    campaignId: slot.campaignId,
                    creativeId: slot.creativeId,
                    lineItemId: slot.lineItemId,
                  },
                },
                {
                  onError: (e) => {
                    if (process.env.NODE_ENV === 'development') {
                      console.error(e)
                    } else {
                      Sentry.captureException(e)
                    }
                  },
                },
              )
          }
        }
      })
    }

    addEventListener('blur', iframeFunction)
    return () => removeEventListener('blur', iframeFunction)
  }, [adDivs, GPTHasLoaded, isUserLoading, track])
}
