import {useController} from 'react-hook-form'
import type {Control} from 'react-hook-form'
import Select from 'react-select'

import {
  HS_ROLE_CONSUMER,
  HS_ROLE_INDUSTRY_PRO,
  HS_ROLE_OPHTHALMIC_TECH,
  HS_ROLE_OPHTHALMOLOGIST,
  HS_ROLE_OPTICIAN,
  HS_ROLE_OPTOMETRIC_TECH,
  HS_ROLE_OPTOMETRIST,
  HS_ROLE_STAFF,
} from '../../core/constants'
import {SignUpFormControl} from './SignUpForm'

export const roles = [
  {label: 'Optometrist', value: HS_ROLE_OPTOMETRIST},
  {label: 'Ophthalmologist', value: HS_ROLE_OPHTHALMOLOGIST},
  {label: 'Optician', value: HS_ROLE_OPTICIAN},
  {label: 'Optometric Technician', value: HS_ROLE_OPTOMETRIC_TECH},
  {label: 'Ophthalmic Technician', value: HS_ROLE_OPHTHALMIC_TECH},
  {label: 'Administrative/Practice Staff', value: HS_ROLE_STAFF},
  {label: 'Industry Professional', value: HS_ROLE_INDUSTRY_PRO},
  {label: 'Consumer/Patient', value: HS_ROLE_CONSUMER},
]

/**
 * Render a single select dropdown of eyecare roles.
 */
const RolesSelectInput = ({control}: {control: Control<SignUpFormControl>}) => {
  const {
    field: {onChange, onBlur, name, value},
  } = useController({
    name: 'role',
    control,
    rules: {required: 'Role is required.'},
  })

  return (
    <Select
      id="roles"
      onChange={(optionValue) => {
        optionValue && onChange(optionValue.value)
      }}
      onBlur={onBlur}
      value={roles.find((roleValue) => value === roleValue.value)}
      name={name}
      options={roles}
    />
  )
}
export default RolesSelectInput
