import {useContext, useEffect} from 'react'

import {PortableText} from '@portabletext/react'

import {getImageSource} from '../../../core/sanity'
import serializers, {featuredTextSerializers} from '../../../core/serializers'
import ImageComponent from '../../images/ImageComponent'
import StickyFooterContent from '../../layouts/StickyFooterContent'
import {ResourceComponentContext} from '../ResourceComponent'
import Citations from './Citations'
import TableOfContents from './TableOfContents'

export default function Body() {
  const {resource, tableOfContents} = useContext(ResourceComponentContext)

  // The CTAWidget component is required to be mounted within the body of the resource page.
  // To achieve this, a 'ctaWidget' type PortableText block is inserted before the first <h2> element (if it exists).
  // The serializers then use this block to render the CTAWidget component on the page.
  useEffect(() => {
    if (!resource.body) return
    const widgetPosition = resource.body.findIndex((obj) => obj.style === 'h2')
    if (widgetPosition) {
      resource.body.splice(widgetPosition, 0, {
        key: `${resource.slug}_cta`,
        _type: 'ctaWidget',
      })
    }
  }, [resource.body, resource.slug])

  return (
    <div className="resource-body break-word">
      {resource.featuredText && (
        <div className="font-nunito mb-3 lead">
          <PortableText
            value={resource.featuredText}
            components={featuredTextSerializers}
          />
        </div>
      )}
      {resource.showFeaturedImage && resource.featuredImage && (
        <ImageComponent
          priority={true}
          alt={resource.title}
          src={getImageSource(resource.featuredImage.asset).url()}
          width={resource.featuredImage.dimensions.width}
          height={resource.featuredImage.dimensions.height}
          fancyboxId={`gallery_${resource._id}`}
        />
      )}

      {tableOfContents?.length > 0 && (
        <TableOfContents sections={tableOfContents} />
      )}

      {resource.body && (
        <div className="my-3 text-body">
          <article id={`resource-body__${resource._id}`}>
            <PortableText value={resource.body} components={serializers} />
          </article>
        </div>
      )}
      {resource.stickyFooter && (
        <StickyFooterContent stickyFooter={resource.stickyFooter} />
      )}
      <Citations />
    </div>
  )
}
