import {useContext, useEffect, useState} from 'react'

import {
  faChevronCircleDown,
  faCircleX,
} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

import {StickyFooterType} from '../../core/schemas'
import {featuredTextSerializers, serializers} from '../../core/serializers'
import {isElementVisible} from '../../core/utils'
import styles from '../../styles/modules/ResourceStickyFooter.module.scss'
import {ResourceComponentContext} from '../resources/ResourceComponent'

export default function StickyFooter({
  stickyFooter,
}: {
  stickyFooter: StickyFooterType
}) {
  const [visible, setVisible] = useState(true)
  const {resource} = useContext(ResourceComponentContext)

  // State to manage visibility
  const [isDismissed, setisDismissed] = useState(true)

  // Check session storage on mount to see if the footer has been dismissed
  useEffect(() => {
    const isDismissed =
      sessionStorage.getItem(`${resource._id}_stickyFooterDismissed`) === 'true'
    setisDismissed(!isDismissed)
  }, [resource._id])

  // Dismiss handler
  const handleDismiss = () => {
    setisDismissed(false)
    sessionStorage.setItem(`${resource._id}_stickyFooterDismissed`, 'true')
  }

  // Scroll handler to show/hide the sticky footer
  useEffect(() => {
    const handleScroll = () => {
      setVisible(
        !isElementVisible(document.getElementById('stickyFooterContent')),
      )
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // If the sticky footer is not visible or has been dismissed, hide it
  if (!visible || !isDismissed) {
    return <></>
  }

  return (
    <div
      className={`${styles.stickyFooter} fixed-bottom justify-content-center`}
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-start py-3">
          <div className={`${styles.stickyFooterCTA} me-5`}>
            <PortableText
              value={stickyFooter.stickyFooterCTA}
              components={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: TODO: convert serializers to typescript
                block: {
                  ...serializers.block,
                },
                marks: {
                  ...featuredTextSerializers.marks,
                  textColor: (props) => (
                    <span style={{color: props.value.hex}}>
                      {props.children}
                    </span>
                  ),
                },
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <a href="#stickyFooterContent">
              <FontAwesomeIcon
                role="button"
                size="2x"
                className="text-primary me-2 me-md-0"
                icon={faChevronCircleDown}
              />
            </a>
            {/* Dismiss "X" icon for mobile */}
            <div className={styles.dismissIcon} onClick={handleDismiss}>
              <FontAwesomeIcon
                role="button"
                size="2x"
                className="text-muted"
                icon={faCircleX}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
