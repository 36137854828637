import Script from 'next/script'

import {useUser} from '../../core/auth'
import {ResourceProjection} from '../resources/schemas'
import {ANALYTICS_ENABLED, G_GA4_ID} from './config'

interface GoogleProps {
  event_category?: string
  event_label?: string
  page_location?: string
  page_title?: string
  send_to?: string
  value?: string
}

interface CustomDimensions {
  dimension5?: string
  dimension6?: 'True' | 'False'
  dimension7?: string
  dimension8?: string
  dimension11?: string
  dimension16?: string
  dimension17?: string
  dimension21?: string
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackGoogleEvent = (
  action: string,
  props: GoogleProps,
  customDimensions?: CustomDimensions,
) => {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window.gtag('event', action, {
      send_page_view: false,
      ...props,
      ...customDimensions,
    })
  } catch (e) {
    // continue regardless of error
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const trackGooglePageview = (url: string) => {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window.gtag('event', 'page_view', {
      page_path: url,
    })
  } catch (e) {
    // continue regardless of error
  }
}

// used for OutboundLinks script
export const setGAPageProperties = (resource: ResourceProjection) => {
  if (window.GAPageProperties) {
    if (resource.roles) {
      window.GAPageProperties.dimension5 = resource.roles.join()
    }
    window.GAPageProperties.dimension6 = resource.containsGatedContent
      ? 'True'
      : 'False'
    window.GAPageProperties.dimension7 = resource.mediaType
    window.GAPageProperties.dimension8 = resource.category
    if (resource.sponsor) {
      window.GAPageProperties.dimension11 = resource.sponsor.uuid ?? undefined
      window.GAPageProperties.dimension21 = resource.sponsor.slug
    }
    window.GAPageProperties.dimension16 = resource.clinicalSpecialty
    if (resource.includeCampaignTracker)
      window.GAPageProperties.dimension17 = 'True'
  }
}

export function GoogleAnalyticsInit() {
  if (!ANALYTICS_ENABLED) return null

  return (
    <>
      <Script
        strategy="afterInteractive"
        type="text/plain"
        data-cookieconsent="statistics"
        src={`https://www.googletagmanager.com/gtag/js?id=${G_GA4_ID}`}
      />
      <Script
        id="gtag-init"
        type="text/plain"
        data-cookieconsent="statistics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.GAPageProperties = {};
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('set', 'linker', {
              accept_incoming: true,
              domains: ['courses.eyesoneyecare.com', 'glance.eyesoneyecare.com', 'events.eyesoneyecare.com'],
            });
            gtag('js', new Date());
            gtag('config', '${G_GA4_ID}', {
              custom_map: {
                dimension2: 'User ID',
                dimension5: 'Role',
                dimension6: 'Contains Gated Content',
                dimension7: 'Primary Media Type',
                dimension8: 'Topic',
                dimension9: 'Contact Type',
                dimension10: 'Is Consumer Content',
                dimension11: 'Company',
                dimension12: 'CovalentCareers Managed',
                dimension13: 'City',
                dimension14: 'State',
                dimension16: 'Clinical Specialty',
                dimension17: 'Campaign Tracker',
                dimension21: 'Company Slug',
              },
            });
          `,
        }}
      />
    </>
  )
}

export function GoogleAnalyticsUserInit() {
  const {user, isLoading: isAuthenticating} = useUser()

  if (!ANALYTICS_ENABLED || isAuthenticating) return null

  return (
    <>
      {user && (
        <Script
          id="gtag-user-config"
          type="text/plain"
          data-cookieconsent="statistics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              if (window.GAPageProperties) {
                window.GAPageProperties.dimension2 = ${user.id}
                window.GAPageProperties.user_id = ${user.id}
              }
              window.gtag && window.gtag('config', '${G_GA4_ID}', {
                send_page_view: false,
                "User ID": ${user.id}, // for custom dimension
                user_id: ${user.id},
              })
            `,
          }}
        />
      )}
    </>
  )
}
