import {useContext, useEffect, useState} from 'react'

import {PortableText} from '@portabletext/react'

import {useUser} from '../../core/auth'
import serializers from '../../core/gatedSerializers'
import {ANALYTICS_ENABLED} from '../analytics/config'
import {parselyTrackLeadCapture} from '../analytics/parsely'
import {trackTwitterEvent, twitterEventIdMap} from '../analytics/twitter'
import AuthenticationForm from '../forms/AuthenticationForm'
import {ResourceComponentContext} from '../resources/ResourceComponent'

const GatedContent = ({gatedContent}) => {
  const {resource: page, url} = useContext(ResourceComponentContext)
  const {user} = useUser()
  const gatedContentEmoji = page.gatedContentEmoji ?? '📚'
  const gatedContentHeading =
    page.gatedContentHeading ?? 'Keep reading for free!'
  const gatedContentSubHeading =
    page.gatedContentSubheading ??
    'Every article, ebook, and video is 100% free.'
  const [redirectUrl, setRedirectUrl] = useState(null)

  // Set the current url on component mount and when the route changes
  useEffect(() => {
    let gatedUrl = url
    if (!gatedUrl.includes('#gated-content')) {
      gatedUrl = `${gatedUrl}#gated-content`
    }
    setRedirectUrl(gatedUrl)
  }, [url])

  return (
    <div
      id="gated-content"
      style={{backgroundColor: `rgb(245, 245, 245)`}}
      className="gated-content p-4 p-lg-5 mb-4 mt-3 shadow"
      data-resource-slug={page.slug}
    >
      {/* Gated Header */}
      <div id="download" className="d-flex flex-column text-center px-3">
        <div className="display-6">{gatedContentEmoji}</div>
        <h2>{gatedContentHeading}</h2>
        <div className="text-muted mb-3">{gatedContentSubHeading}</div>
      </div>

      {/* Content | Sign Up */}
      {user ? (
        <UnGatedContent gatedContent={gatedContent} user={user} />
      ) : (
        <AuthenticationForm
          initialState="signUp"
          submitButtonText="Unlock Content"
          redirectTo={redirectUrl}
          gatedContentSlug={page.slug}
          pageTitle={page.title}
          pageRoles={page.roles}
          gatedContentOptInLabel={page.gatedContentOptInLabel}
          orgUUID={page.sponsor?.uuid}
        />
      )}
    </div>
  )
}

const UnGatedContent = ({gatedContent}) => {
  const {user} = useUser()
  const firstName = user.profile?.first_name

  // Use this function to run conversion tracking & analytics events.
  const handleClick = (redirectUrl) => {
    if (ANALYTICS_ENABLED) {
      // Parsely
      parselyTrackLeadCapture('EOE - Access Gated Content')
      // Twitter pixel conversion tracking
      if (redirectUrl.includes('Eyes-On-Eyecare-2022-Optometrist-Report.pdf')) {
        trackTwitterEvent(
          twitterEventIdMap.optometry[
            'Eyes On Eyecare 2022 Optometrist Report'
          ],
        )
      }
    }
    window.open(redirectUrl)
  }

  let body
  if (gatedContent.type === 'redirect') {
    body = (
      <span
        className="btn btn-lg btn-primary"
        role="button"
        onClick={() => handleClick(gatedContent.redirectUrl)}
      >
        Access Resource
      </span>
    )
  } else {
    body = (
      <div className="p-3 w-100">
        <PortableText value={gatedContent.body} components={serializers} />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column align-items-center mt-3">
      <p className="lead mb-2">
        Good to see you again{firstName && `, ${firstName}`}!
      </p>
      {body}
    </div>
  )
}

export default GatedContent
