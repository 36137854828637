import Cookies from 'js-cookie'
import qs from 'qs'
import {z} from 'zod'

import {MixpanelDataInputSchema} from '../components/analytics/mixpanel'
import {removeFalsy} from './utils'

export const baseBackendUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/api/v1'
    : 'https://api.eyesoneyecare.com/api/v1'

/** Return an async fetch function to be passed as the query function
 * to react-query.
 *
 * Encapsulates basic fetch logic including throwing network errors in order to
 * reject the promise (required by react-query), included session cookies, and
 * returning the json response.
 */
const jsonQuery = ({
  endpoint,
  errorMessage = 'Error fetching data.',
}: {
  endpoint: RequestInfo | URL
  errorMessage?: string
}) => {
  return async () => {
    const res = await fetch(endpoint, {credentials: 'include'})
    if (!res.ok) {
      throw new Error(errorMessage)
    }
    return res.json()
  }
}

export const getInfiniteScrollResources = ({
  roles,
  primaryResourceSlug,
  templateType,
  clinicalSpecialty,
  limit,
}: {
  roles?: Array<string>
  primaryResourceSlug?: string
  templateType?: string
  clinicalSpecialty?: string
  limit?: number
}) => {
  const query = qs.stringify(
    removeFalsy({
      roles,
      primaryResourceSlug,
      templateType,
      clinicalSpecialty,
      limit,
    }),
    {
      addQueryPrefix: true,
      arrayFormat: 'comma',
      encodeValuesOnly: true,
      format: 'RFC1738',
    },
  )
  return jsonQuery({
    endpoint: `/api/resources/get-infinite-scroll-resources/${query}`,
  })
}

const jsonMutation = async ({
  endpoint,
  data = {},
}: {
  endpoint: string
  // Used in JSON.stringify, which takes `any` as its only parameter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}) => {
  const res = await fetch(endpoint, {
    method: 'post',
    credentials: 'include',
    headers: {
      'x-csrftoken': Cookies.get('eoecsrftoken') || '',
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (!res.ok) {
    const json = await res.json()
    throw new Error(
      json.message ??
        json ??
        `Something went wrong. Please try again (HTTP ${res.status})`,
    )
  }
  return res.json()
}

export const SignInUserVariablesSchema = z.object({
  email: z.string(),
  next: z.string().optional(),
})
export type SignInUserVariables = z.infer<typeof SignInUserVariablesSchema>

export const SignInUserDataSchema = z.object({
  message: z.string(),
})
export type SignInUserData = z.infer<typeof SignInUserDataSchema>

export const SignInUserErrorSchema = z.object({
  message: z.string(),
})
export type SignInUserError = z.infer<typeof SignInUserErrorSchema>

export const signInUser = (data: SignInUserVariables) =>
  jsonMutation({
    endpoint: `${baseBackendUrl}/auth/sign-in/`,
    data,
  })

export const recordAuthorHit = (slug: string) =>
  jsonMutation({
    endpoint: `${baseBackendUrl}/resources/author/${slug}/hit/`,
  })

export const requestPreferencesLink = () =>
  jsonMutation({
    endpoint: `${baseBackendUrl}/subscriptions/request-preferences-link/`,
  })

export const SubmitGatedContentVariablesSchema = z.object({
  country: z.string(),
  email: z.string(),
  full_name: z.string(),
  gated_content_slug: z.string(),
  graduation_year: z.number(),
  is_practice_owner: z.boolean(),
  redirect_to: z.string(),
  role: z.string(),
  specialties: z.array(z.string()),
  opted_in: z.boolean().optional(),
  org_uuid: z.string().optional(),
  mixpanel_data: MixpanelDataInputSchema,
})
export type SubmitGatedContentVariables = z.infer<
  typeof SubmitGatedContentVariablesSchema
>

export const SubmitGatedContentDataSchema = z.object({
  user_id: z.string(),
  message: z.string(),
  email: z.string(),
})
export type SubmitGatedContentData = z.infer<
  typeof SubmitGatedContentDataSchema
>

export const SubmitGatedContentErrorSchema = z.object({
  error: z.string(),
})
export type SubmitGatedContentError = z.infer<
  typeof SubmitGatedContentErrorSchema
>

export const submitGatedContent = (formData: SubmitGatedContentVariables) => {
  return jsonMutation({
    endpoint: `${baseBackendUrl}/resources/gated-content-submission/`,
    data: formData,
  })
}

export const GetFeaturedJobsVariablesSchema = z.object({
  featuredJobsTag: z.string().nullable(),
  field: z.string(),
})
export type GetFeaturedJobsVariables = z.infer<
  typeof GetFeaturedJobsVariablesSchema
>

export const GetFeaturedJobsDataSchema = z.array(
  z.object({
    image: z.string(),
    location: z.string(),
    title: z.string(),
    url: z.string(),
  }),
)

export const getFeaturedJobs = (formData: GetFeaturedJobsVariables) => {
  return jsonMutation({
    endpoint:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8082/jobs/featured-jobs/'
        : 'https://eyesoneyecare.com/jobs/featured-jobs/',
    data: formData,
  })
}
