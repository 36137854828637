import {useEffect} from 'react'

import JSConfetti from 'js-confetti'

let jsConfetti: JSConfetti | undefined

export function useConfetti() {
  useEffect(() => {
    if (!jsConfetti) {
      jsConfetti = new JSConfetti()
    }
  }, [])

  const addConfetti = (emojis: string[] = []) => {
    jsConfetti &&
      jsConfetti.addConfetti({
        // use smaller confettiNumber for emojis due to their density
        confettiNumber: emojis.length > 0 ? 250 : 500,
        emojis,
      })
  }

  const clearConfetti = () => {
    jsConfetti && jsConfetti.clearCanvas()
  }

  return {addConfetti, clearConfetti}
}
