import {AdRollInit} from './adroll'
import {ANALYTICS_ENABLED} from './config'
import {FacebookPixelInit} from './facebook'
import {GoogleAnalyticsInit, GoogleAnalyticsUserInit} from './google'
import {HubspotInit} from './hubspot'
import {LinkedInInit} from './linkedin'
import {initializeMixpanel} from './mixpanel'
import {ParselyInit} from './parsely'
import {SmartRecognitionInit} from './smartRecognition'
import {TwitterInit} from './twitter'

export default function AnalyticsInits() {
  if (ANALYTICS_ENABLED) {
    initializeMixpanel()
  }

  return ANALYTICS_ENABLED ? (
    <>
      <GoogleAnalyticsInit />
      <GoogleAnalyticsUserInit />
      <FacebookPixelInit />
      <AdRollInit />
      <LinkedInInit />
      <HubspotInit />
      <ParselyInit />
      <TwitterInit />
      <SmartRecognitionInit />
    </>
  ) : null
}
