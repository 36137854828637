import {z} from 'zod'

import {ResourceProjectionSchema} from '../../resources/schemas'

const options: {
  Eyecare: string[]
  Optometry: string[]
  Ophthalmology: string[]
} = {
  Eyecare: [
    'Ocular Surface',
    'Primary Care',
    'Contact Lens',
    'Retina',
    'Glaucoma',
    'Practice Management',
  ],
  Optometry: [
    'Contact Lens',
    'Cornea',
    'Glaucoma',
    'Low Vision',
    'Neuro',
    'Non-Clinical',
    'Ocular Surface',
    'Primary Care',
    'Refractive Management',
    'Retina',
    'Systemic Disease',
    'Vision Therapy',
  ],
  Ophthalmology: [
    'Cataract',
    'Cornea',
    'Glaucoma',
    'Neuro',
    'Non-Clinical',
    'Ocular Surface',
    'Primary Care',
    'Retina',
    'Systemic Disease',
    'Refractive Management',
    'Refractive Surgery',
    'Vision Therapy',
  ],
}

const ClinicalSpecialtyDropdownPropsSchema = z.object({
  currentClinicalSpecialty: ResourceProjectionSchema.shape.clinicalSpecialty,
  field: z.enum(['Eyecare', 'Optometry', 'Ophthalmology']),
})

export default function ClinicalSpecialtyDropdown({
  currentClinicalSpecialty,
  field,
}: z.infer<typeof ClinicalSpecialtyDropdownPropsSchema>) {
  return (
    <span className="text-small text-dark text-uppercase">
      <a
        className="dropdown-toggle"
        href="#"
        id="clinicalSpecialtyDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {currentClinicalSpecialty}
      </a>
      <div className="dropdown-menu">
        {options[field].map((option) => (
          <span key={option}>
            <a
              href={`/resources/?clinical_specialty=${option.replace(
                ' ',
                '+',
              )}`}
              className="dropdown-item"
            >
              {option}
            </a>
          </span>
        ))}
      </div>
    </span>
  )
}
