import Link from 'next/link'

import {
  faCalendarCheck,
  faEye,
  faHandshake,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

import ImageComponent from '../components/images/ImageComponent'
import Flashcards from '../components/resources/blocks/Flashcards'
import PDFEmbed from '../components/resources/blocks/PDFEmbed'
import ThirdPartyEmbedBlock from '../components/resources/blocks/ThirdPartyEmbedBlock'
import VideoEmbedBlock from '../components/resources/blocks/VideoEmbedBlock'
import {getImageSource} from './sanity'

const serializers = {
  block: {
    h2: ({children}) => {
      return (
        <h2 className="font-nunito font-weight-800 text-size-h2">{children}</h2>
      )
    },
    h3: ({children}) => {
      return (
        <h3 className="font-nunito font-weight-800 text-size-h3">{children}</h3>
      )
    },
    h4: ({children}) => {
      return (
        <h4 className="font-nunito font-weight-700 text-size-blockquote">
          {children}
        </h4>
      )
    },
    normal: ({children}) => {
      return <p className="mb-3">{children}</p>
    },
  },
  types: {
    highlight: ({value}) => {
      return (
        <div className={`alert alert-${value.color} pb-0`}>
          <PortableText value={value.body} />
        </div>
      )
    },
    reference: ({value}) => {
      const {unfurledRef: richText} = value
      if (!richText) return
      let icon
      switch (richText.icon) {
        case 'handshake':
          icon = faHandshake
          break
        case 'eye':
          icon = faEye
          break
        case 'calendar-check':
          icon = faCalendarCheck
          break
        default:
          icon = null
      }
      return (
        <div
          className={`alert alert-${richText.color} pb-0 d-flex align-items-center`}
        >
          {icon && (
            <div style={{marginRight: '1rem'}} className="mb-3">
              <FontAwesomeIcon
                icon={icon}
                className={richText?.iconColor && `text-${richText.iconColor}`}
              />
            </div>
          )}
          <div>
            <PortableText value={richText.text} />
          </div>
        </div>
      )
    },
    blockQuote: ({value}) => {
      return (
        <h4 className="font-nunito text-center font-weight-800 text-size-blockquote">
          &ldquo;{value.quote}&rdquo;
        </h4>
      )
    },
    pdfEmbed: ({value}) => {
      return <PDFEmbed url={value.pdfUrl} />
    },
    image: ({value}) => {
      return (
        <ImageComponent
          src={getImageSource(value.asset).url()}
          width={value.dimensions.width}
          height={value.dimensions.height}
          fancyboxId={value._key}
        />
      )
    },
    videoEmbed: ({value}) => {
      return <VideoEmbedBlock url={value.url} />
    },
    thirdPartyEmbed: ({value}) => {
      return <ThirdPartyEmbedBlock value={value} />
    },
    flashcards: ({value}) => {
      return <Flashcards {...value} />
    },
  },
  marks: {
    link: ({children, value}) => {
      return (
        <Link
          href={value.href}
          className="text-primary text-decoration-underline"
        >
          {children}
        </Link>
      )
    },
    superscript: ({children}) => {
      return <sup>{children}</sup>
    },
    subscript: ({children}) => {
      return <sub>{children}</sub>
    },
  },
}

export default serializers
