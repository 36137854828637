import {type ReactNode, createContext, useEffect, useState} from 'react'

import {useUser} from '../../../auth'
import {useGPT} from '../GPTContext'
import {_useAdTracking} from './hooks'

export type _AdDivInfo = Record<
  string,
  {
    adUnitPath: string
    advertiserId: string | null
    campaignId: string | null
    creativeId: string | null
    lineItemId: string | null
  }
>

export const AdTrackingContext = createContext<{
  adDivs: _AdDivInfo
}>({
  adDivs: {},
})

export function AdTrackingProvider({children}: {children: ReactNode}) {
  const [adDivs, setAdDivs] = useState<_AdDivInfo>({})
  const {GPTHasLoaded} = useGPT()
  const {user} = useUser()

  // ad slot information to corresponding div when slot renders have ended
  useEffect(() => {
    if (
      !GPTHasLoaded ||
      // ad tracking is not used when GPT user is not authenticated
      !user
    )
      return

    function onSlotRenderEnded(event: googletag.events.SlotRenderEndedEvent) {
      setAdDivs((prevAdDivs) => ({
        ...prevAdDivs,
        [event.slot.getSlotElementId()]: {
          adUnitPath: event.slot.getAdUnitPath(),
          advertiserId: event.advertiserId
            ? event.advertiserId.toString()
            : null,
          campaignId: event.campaignId ? event.campaignId.toString() : null,
          creativeId: event.creativeId ? event.creativeId.toString() : null,
          lineItemId: event.lineItemId ? event.lineItemId.toString() : null,
        },
      }))
    }

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded)
    })

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded)
      })
    }
  }, [GPTHasLoaded, user])

  _useAdTracking(adDivs)

  return (
    <AdTrackingContext.Provider
      value={{
        adDivs,
      }}
    >
      {children}
    </AdTrackingContext.Provider>
  )
}
