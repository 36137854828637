import {useEffect, useState} from 'react'
import {useController} from 'react-hook-form'
import type {Control} from 'react-hook-form'
import type {DeepRequired, FieldErrorsImpl} from 'react-hook-form'

import {HS_ROLE_OPTOMETRIST} from '../../core/constants'
import FormError from './FormError'
import {SignUpFormControl} from './SignUpForm'

export default function GraduationYearInput({
  control,
  errors,
  required,
  role,
}: {
  control: Control<SignUpFormControl>
  errors: FieldErrorsImpl<DeepRequired<SignUpFormControl>>
  required?: boolean
  role: string
}) {
  const [label, setLabel] = useState('')

  useEffect(() => {
    if (role === HS_ROLE_OPTOMETRIST) {
      setLabel('What year did you or will you graduate from optometry school?')
    } else {
      setLabel('What year did you or will you finish your residency?')
    }
  }, [role])

  const {
    field: {onChange, onBlur, name, value},
  } = useController({
    name: 'graduation_year',
    control,
    defaultValue: new Date().getFullYear(),
    rules: {required: required ? 'Graduation year is required.' : false},
  })

  return (
    <>
      <label htmlFor="gradYear" className="form-label mb-0">
        {label}
        <span className="text-danger">*</span>
      </label>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FormError name="graduation_year" errors={errors} />
      <input
        id="gradYear"
        className="form-control"
        type="number"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
      />
    </>
  )
}
