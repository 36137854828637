import Script from 'next/script'

export default function OutboundLinks() {
  return (
    <Script id="outbound-links" strategy="afterInteractive">
      {`
        ! function() {
          var t = function(t, e, f) {
                !f && (e ? window.open(t, e) : window.location.href = t)
              },
              e = function(t, e, n) {
                t.addEventListener ? t.addEventListener(e, n) : t.attachEvent("on" + e, function() {
                  n.call(t)
                })
              };
          if (document.getElementsByTagName)
            for (var n = document.getElementsByTagName("a"), a = 0; a < n.length; a++) {
              if (n[a].hostname !== location.hostname) {
                e(n[a], "click", function(e) {
                  var n = this.getAttribute("href"),
                    i = "string" == typeof this.getAttribute("target") ? this.getAttribute("target") : "",
                    a = window.GAPageProperties,
                    f = this.getAttribute("data-fancybox") === "";
                  if (a) {
                    a.event_callback = !f && e.preventDefault(), a.nonInteraction = 0, a.event_category = 'outbound', a.event_label = n, gtag("event", "click", a), t(n, i, f)
                  }
                })
              }
            }
        }();
      `}
    </Script>
  )
}
