import {useContext} from 'react'

import {faEye, faGlasses, faUserMd} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {ResourceComponentContext} from '../ResourceComponent'

export default function SidebarLeadForm({
  buttonCTAText,
  copy,
  buttonColor,
  href,
  icon,
  iconColor,
  title,
}) {
  const {resourceIndex} = useContext(ResourceComponentContext)
  const leadFormId = `leadForm-${resourceIndex}`
  let faIcon
  switch (icon) {
    case 'eye':
      faIcon = faEye
      break
    case 'user-md':
      faIcon = faUserMd
      break
    case 'glasses':
      faIcon = faGlasses
      break
    default:
      break
  }
  return (
    <div className="p-3 border rounded shadow" id="leadForm">
      <h5>
        {faIcon && (
          <FontAwesomeIcon
            icon={faIcon}
            className={iconColor && `text-${iconColor}`}
          />
        )}{' '}
        {title}
      </h5>

      <p style={{color: '#495057!important'}}>{copy}</p>
      {href ? (
        <a
          href={href}
          className={`btn w-100 btn-${buttonColor} btn-lg text-white mt-3 rounded`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonCTAText}
        </a>
      ) : (
        <a
          href={`#${leadFormId}`}
          className={`btn w-100 btn-${buttonColor} btn-lg text-white mt-3 rounded`}
        >
          {buttonCTAText}
        </a>
      )}
    </div>
  )
}
