import {useEffect} from 'react'

/**
 * The publication team needs to embed some third party scripts that need to
 * be loaded outside of our NextJS rendering pipeline. This component appends
 * scripts/styles to the DOM and then renders the third party embed.
 */
function ThirdPartyEmbedBlock({value}) {
  useEffect(() => {
    if (value.loadingSteps) {
      value.loadingSteps.forEach((step) => {
        if (!step?.srcType || !step?.code) return
        const element = document.createElement(step.srcType)
        element.src = step.code
        document.body.appendChild(element)
      })
    }
  }, [value.loadingSteps])
  return <div dangerouslySetInnerHTML={{__html: value.embedCode}} />
}

export default ThirdPartyEmbedBlock
