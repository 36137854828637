import {useContext, useEffect, useMemo, useState} from 'react'

import {faTimes} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {useGPT} from '../../../core/ads/contexts/GPTContext'
import {createAd, getAdSpecs, shouldUseCustomAds} from '../../../core/ads/utils'
import {useUser} from '../../../core/auth'
import useHasMounted from '../../../core/hooks/useHasMounted'
import {ResourcePageContext} from '../../../pages/resources/[slug]'
import styles from '../../../styles/StickyFooter.module.scss'

export default function ResourceStickyFooterAd() {
  const {GPTHasLoaded} = useGPT()
  const {resource} = useContext(ResourcePageContext)
  const hasMounted = useHasMounted()
  const [dismissed, setDismissed] = useState(false)
  const [adLoaded, setAdLoaded] = useState(false) // can't dismiss the component entirely otherwise google can't find the div
  const {user, isLoading: isUserLoading} = useUser()

  // Use useMemo since adSpecs triggers re-rendering due to its usage in the useEffect
  const adSpecs = useMemo(() => {
    const adCode =
      shouldUseCustomAds(resource) && resource.customAd?.stickyFooterCode
        ? resource.customAd?.stickyFooterCode
        : 'eoe_resources_footer'
    return getAdSpecs(adCode, 'stickyFooter', resource._id)
  }, [resource])
  const {id: adId} = adSpecs

  // Create ad and pass onAdRendered callback
  useEffect(() => {
    if (
      hasMounted &&
      resource.showAds &&
      adId &&
      GPTHasLoaded &&
      !isUserLoading &&
      // Do not display sticky footer ad if the resource is trying to display
      // its own sticky footer content.
      !resource.stickyFooter &&
      !dismissed
    ) {
      return createAd(adSpecs, GPTHasLoaded, user, resource, () =>
        setAdLoaded(true),
      )
    }
  }, [
    adId,
    adSpecs,
    dismissed,
    GPTHasLoaded,
    hasMounted,
    isUserLoading,
    resource,
    user,
  ])

  const containerStyle = {
    backgroundColor: adLoaded ? 'rgba(0,0,0, 0.4)' : 'transparent',
  }

  if (
    !hasMounted ||
    !resource?.showAds ||
    // Do not display sticky footer ad if the resource is trying to display
    // its own sticky footer content.
    resource.stickyFooter ||
    dismissed
  ) {
    return <></>
  }

  return (
    <div
      className={`fixed-bottom justify-content-center ${styles.resourceOuterContainer}`}
      id={`${adId}-container`}
      style={containerStyle}
    >
      <div
        className={`${styles.innerContainer} d-flex flex-column flex-lg-row align-items-end align-items-lg-start`}
      >
        <div className="order-1 order-lg-0" id={adId} />
        {adLoaded && (
          <FontAwesomeIcon
            icon={faTimes}
            role="button"
            onClick={() => setDismissed(true)}
            className={`border bg-white ${styles.closeButton} order-0 order-lg-1`}
          />
        )}
      </div>
    </div>
  )
}
