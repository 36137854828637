import Script from 'next/script'

import Cookies from 'js-cookie'

import {baseUrl} from '../../core/constants'
import {ANALYTICS_ENABLED, PARSELY_SITE_ID} from './config'

export const parselyPageView = (url: string) => {
  try {
    window.PARSELY.beacon.trackPageView({
      url: `${baseUrl}${url}`,
      urlref: document.referrer || Cookies.get('eoereferrer'),
      js: 1,
    })
  } catch (e) {
    // continue regardless of error
  }
}

export const parselyTrackLeadCapture = (label: string) => {
  const enabled = ['EOE - Submit Gated Content Form']
  // We're only paying fot a single parsely conversion right now
  if (!enabled.includes(label)) return
  if (ANALYTICS_ENABLED) {
    try {
      window.PARSELY.conversions.trackLeadCapture(label)
    } catch (e) {
      // continue regardless of error
    }
  }
}

export function ParselyInit() {
  return (
    <>
      {ANALYTICS_ENABLED && (
        <Script
          id="parsely"
          type="text/plain"
          data-cookieconsent="statistics"
          src={`https://cdn.parsely.com/keys/${PARSELY_SITE_ID}/p.js`}
        />
      )}
    </>
  )
}
