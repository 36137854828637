import {createRef, useEffect} from 'react'

import {create} from '@lottiefiles/lottie-interactivity'

export default function LottieFile({_key, loop, height, lottieUrl}) {
  const lottieRef = createRef()

  useEffect(() => {
    import('@lottiefiles/lottie-player')
    lottieRef.current.addEventListener('load', () => {
      create({
        player: `#lottie-${_key}`,
        mode: 'scroll',
        actions: [
          {
            visibility: [0.3, 1.0],
            type: loop ? 'loop' : 'playOnce',
            frames: [0],
          },
        ],
      })
    })
  }, [_key, loop, lottieRef])

  return (
    <div className="d-flex justify-content-center my-3">
      <lottie-player
        ref={lottieRef}
        mode="normal"
        background="transparent"
        speed="1"
        className="img-fluid"
        id={`lottie-${_key}`}
        src={lottieUrl}
        style={{
          width: 'auto',
          height: height ? `${height}px` : 'auto',
        }}
      />
    </div>
  )
}
