import {ZodType, z} from 'zod'

import {ContentBuilderSchema} from '../../../core/schemas'
import {getGenericSlug} from '../../../core/utils'
import ContentBlock from '../../cards/ContentBlock'

// https://github.com/colinhacks/zod/issues/1118 since zod doesn't have numeric enums
function numericEnum<TValues extends readonly number[]>(values: TValues) {
  return z.number().superRefine((val, ctx) => {
    if (!values.includes(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_enum_value,
        options: [...values],
        received: val,
      })
    }
  }) as ZodType<TValues[number]>
}

const ContentGridBlockProps = z.object({
  blocks: z.array(ContentBuilderSchema),
  textAlignment: z.enum(['left', 'center']).nullish(),
  columnOverride: numericEnum([1, 2, 3, 4, 5, 6]).nullish(),
})

export default function ContentGridBlock({
  blocks,
  textAlignment,
  columnOverride,
}: z.infer<typeof ContentGridBlockProps>) {
  const column = {
    1: '12',
    2: '6',
    3: '4',
    4: '3',
    5: '2',
    6: '2',
  }[columnOverride ?? 3]

  return (
    <div className="row">
      {blocks.map((block) => (
        <div className={`col-sm-${column} mb-3 mb-lg-0 py-3`} key={block._key}>
          <ContentBlock
            body={block.featuredText}
            buttonText={block.buttonText}
            containerClass={`text-${textAlignment}`}
            flexDirection="column"
            image={block.featuredImage}
            imageObjectFit="contain"
            link={getGenericSlug(block._type, block.slug, block.publishedAt)}
            linkInNewTab={block.blank}
            showButton={block.showButton}
            title={<h5 className="mb-1">{block.title}</h5>}
            useVideoEmbed={block.isVideo}
          />
        </div>
      ))}
    </div>
  )
}
