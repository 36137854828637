import {useEffect, useState} from 'react'

import Link from 'next/link'

import {faTiktok} from '@fortawesome/free-brands-svg-icons'
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook'
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin'
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter'
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {z} from 'zod'

const icons = {
  linkedin: faLinkedin,
  instagram: faInstagram,
  facebook: faFacebook,
  youtube: faYoutube,
  twitter: faTwitter,
  tiktok: faTiktok,
}

const SocialLinkComponentProps = z.object({
  className: z.string().optional(),
  link: z.string(),
})

const SocialLinkComponent = ({
  className = 'text-white',
  link,
}: z.infer<typeof SocialLinkComponentProps>) => {
  const [platform, setPlatform] = useState<
    | 'linkedin'
    | 'instagram'
    | 'facebook'
    | 'youtube'
    | 'twitter'
    | 'tiktok'
    | ''
  >('')

  useEffect(() => {
    if (link.includes('linkedin')) {
      setPlatform('linkedin')
    } else if (link.includes('facebook')) {
      setPlatform('facebook')
    } else if (link.includes('instagram')) {
      setPlatform('instagram')
    } else if (link.includes('youtube')) {
      setPlatform('youtube')
    } else if (link.includes('twitter')) {
      setPlatform('twitter')
    } else if (link.includes('tiktok')) {
      setPlatform('tiktok')
    } else {
      setPlatform('')
    }
  }, [link])

  if (!platform) {
    return null
  }

  return (
    <Link
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={`Follow us on ${platform}!`}
    >
      <FontAwesomeIcon icon={icons[platform]} className={className} />
    </Link>
  )
}

export default SocialLinkComponent
