import {useContext, useState} from 'react'
import Collapse from 'react-bootstrap/Collapse'

import {faChevronDown, faChevronUp} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

import serializers from '../../../core/serializers'
import {ResourceComponentContext} from '../ResourceComponent'

export default function Citations() {
  const {resource} = useContext(ResourceComponentContext)
  const [open, setOpen] = useState(false)
  if (!resource.citations) {
    return
  }
  return (
    <div className="mb-4">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <span
              className="h5 m-0"
              role="button"
              icon={open ? faChevronUp : faChevronDown}
              aria-expanded={open}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpen(true)
                }
              }}
              tabIndex={0}
              onClick={() => setOpen(!open)}
            >
              References
            </span>
            <FontAwesomeIcon
              role="button"
              icon={open ? faChevronUp : faChevronDown}
              aria-expanded={open}
              className="text-primary"
              onClick={() => setOpen(!open)}
            />
          </div>
          <Collapse in={open}>
            <div className="mt-3 break-word">
              <PortableText
                value={resource.citations}
                components={serializers}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}
