import {useContext, useEffect} from 'react'

import ArticleSchema from '../../head/ArticleSchema'
import StickyFooter from '../../layouts/StickyFooter'
import EditInSanityButton from '../../sanity/EditInSanityButton'
import {ResourceComponentContext} from '../ResourceComponent'
import ResourceInReadAds from '../ads/ResourceInReadAds'
import ResourceBody from '../common/Body'
import MobileStickyMenu from '../common/MobileStickyMenu'
import RecommendedResources from '../common/RecommendedResources'
import ResourceFooter from '../common/ResourceFooter'
import ResourceHeader from '../common/ResourceHeader'

export default function FullWidthTemplate() {
  const {resource} = useContext(ResourceComponentContext)

  useEffect(() => {
    if (resource.containsLottie) {
      import('@lottiefiles/lottie-player')
    }
  }, [resource.containsLottie])

  return (
    <>
      <ResourceInReadAds />
      <div className="container">
        <EditInSanityButton sanityId={resource._id} sanityType="resourcePage" />
        <div className="row">
          <div className="col">
            <ResourceHeader />
            <ResourceBody />
            <ResourceFooter />
            <RecommendedResources />
          </div>
        </div>
        {resource.stickyFooter && (
          <StickyFooter stickyFooter={resource.stickyFooter} />
        )}
        <MobileStickyMenu />
        <ArticleSchema />
      </div>
    </>
  )
}
