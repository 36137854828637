import {type ReadonlyURLSearchParams} from 'next/navigation'

import Cookies from 'js-cookie'

import {UTMs} from './mixpanel'

/**
 * Get UTM parameters from search params from Next's useSearchParams().
 * Useful for when window can't be immediately accessed in a component.
 * Returns null if no UTM parameters are found.
 */
export function getUTMsFromSearchParams(
  searchParams: ReadonlyURLSearchParams,
): Record<string, string> | null {
  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]

  const utms = utmParams.reduce((acc: Record<string, string>, param) => {
    const value = searchParams.get(param)
    if (value) {
      acc[param] = value
    }
    return acc
  }, {})
  return Object.keys(utms).length ? utms : null
}

/**
 * Get UTM parameters from the URL. Returns null if no UTM parameters are found.
 */
export function getUTMsFromURL(url = ''): UTMs | null {
  let searchParams: URLSearchParams
  if (url) {
    // If url is provided, create a URL object
    const parsedUrl = new URL(url)
    searchParams = new URLSearchParams(parsedUrl.search)
  } else {
    // If url is not provided, use window.location.href
    const parsedUrl = new URL(window.location.href)
    searchParams = new URLSearchParams(parsedUrl.search)
  }

  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]
  const utms = utmParams.reduce((acc: Record<string, string>, param) => {
    const value = searchParams.get(param)
    if (value) {
      acc[param] = value
    }
    return acc
  }, {})
  // If there are no UTM parameters, return null
  return Object.keys(utms).length ? utms : null
}

/**
 * Merge an existing cookie object with a new object.
 */
export function mergeCookie(key: string, newObj: Record<string, string>) {
  const existingObj = Cookies.get(key)
  const obj = existingObj ? JSON.parse(existingObj) : {}
  const updatedObj = JSON.stringify({...obj, ...newObj})

  // the cookie will be treated as a session cookie and will expire when the browser is closed.
  Cookies.set(key, updatedObj, {
    path: '/',
    SameSite: 'Strict',
    Secure: true,
  })
}

/**
 * Save UTM parameters to cookies.
 */
export function saveUTMsToCookie() {
  const utms = getUTMsFromURL()
  if (utms) mergeCookie('utms', utms)
}

/**
 * Get UTM parameters from cookies. Returns an empty object if no UTM parameters are found.
 */
export function getUTMsFromCookie(): UTMs {
  const storedUTMParams = Cookies.get('utms')
  if (storedUTMParams) {
    return JSON.parse(storedUTMParams)
  }
  return {}
}

export function getDocumentTitle(): string | undefined {
  if (typeof document !== 'undefined') {
    return document.title
  }
}
