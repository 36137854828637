interface ImpersonationNavProps {
  email: string
  releaseUrl: string
}

export function ImpersonationNav({email, releaseUrl}: ImpersonationNavProps) {
  return (
    <nav className="sticky-top bg-warning d-none d-lg-block py-3">
      <div className="container">
        <a href={releaseUrl}>
          You are currently working on behalf of {email}. To stop impersonating
          this user, click this link and follow the instructions on the bottom
          of the page.
        </a>
      </div>
    </nav>
  )
}
