import Link from 'next/link'

import {z} from 'zod'

const FieldDropdownPropsSchema = z.object({
  currentField: z.string(),
})

export default function FieldDropdown({
  currentField,
}: z.infer<typeof FieldDropdownPropsSchema>) {
  return (
    <span className="text-small text-dark text-uppercase">
      <a
        className="dropdown-toggle"
        href="#"
        id="fieldDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {currentField}
      </a>
      <div className="dropdown-menu">
        <Link href="/ophthalmology" className="dropdown-item">
          Ophthalmology
        </Link>
        <div className="dropdown-divider" />
        <Link href="/optometry" className="dropdown-item">
          Optometry
        </Link>
      </div>
    </span>
  )
}
